import { observer } from 'mobx-react';
import { Button, Input, InputType, View, ViewHeader, InputOptionBox, InputCalendar } from '../../../../../core/components/Index'
import useAsyncEffect from 'use-async-effect';
import { useNavigate, useParams } from 'react-router-dom';
import { Appl } from '../../../../../core/Appl';
import ManageReleaseNoteViewModel from './ManageReleaseNoteViewModel';
import { AppPermissions } from '../../../../../core/services/AppPermissions';

const ManageReleaseNoteView = observer(() => {
  const vm = Appl.Services.get<ManageReleaseNoteViewModel>('ManageReleaseNoteViewModel');
  vm.navigate = useNavigate();
  let { id } = useParams();
  useAsyncEffect(async () => {
    await vm.initAsync(id as unknown as number);
  }, []);

  return (
    <>
      <View
        id="ManageReleaseNoteView"
        pageTitle={vm.pageTitle}
        authKey={AppPermissions.ReleaseNotes_19_18_VIEW}
        showMenuBar={false}
      >
        <ViewHeader title={vm.pageTitle} description={vm.pageDescription} helpPageId="" backUrl="" />
        <div className="space-y-6 mt-4">
          <div className='grid grid-cols-4 gap-4'>
            <div>
              <Input
                id="buildNbr"
                value={vm.model.buildNbr}
                inputType={InputType.Text}
                onChange={(e: any) => {
                  vm.onInputChange(e, 'buildNbr', vm.model);
                }}
                label="Release#"
                required={true}
                maxLength={10}
              />
            </div>
            <div>
              <InputCalendar
                id="buildDate"
                value={vm.model?.buildDate!}
                label="Release Date"
                onChange={(e: any) => {
                  vm.onInputChange(e, 'buildDate', vm.model);
                }}
                required={true}
              />
            </div>
          </div>
          <div className="grid grid-cols-1">
            <div>
              <Input
                id="buildNotes"
                value={vm.model.buildNotes}
                inputType={InputType.Multiline}
                onChange={(e: any) => {
                  vm.onInputChange(e, 'buildNotes', vm.model);
                }}
                label="Release Notes"
                required={true}
                maxLength={4000}
                rows={13}
                style={{ width: '100%' }}
              />
            </div>
          </div>
          <div className="flex items-center justify-center gap-x-6 my-6">
            <Button title="Submit"
              onClick={async () => await vm.saveAsync()} />
            <Button title="Cancel" severity="secondary" onClick={() => vm.navigate(-1)} />
          </div>
        </div>
      </View >
    </>
  );
});

export default ManageReleaseNoteView;
