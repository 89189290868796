import { Appl } from "../../../../core/Appl";
import { ReleaseNoteService } from "./ReleaseNoteService";
import ReleaseNoteListViewModel from "./list/ReleaseNoteListViewModel";
import ManageReleaseNoteViewModel from "./addEdit/ManageReleaseNoteViewModel";
import ReleaseNotesViewModel from "./note/ReleaseNotesViewModel";

export default class ReleaseNoteModule {
    public static init(): void {
        Appl.Services.add('IReleaseNoteService', ReleaseNoteService);
        Appl.Services.add('ReleaseNotesViewModel', ReleaseNotesViewModel);
        Appl.Services.add('ReleaseNoteListViewModel', ReleaseNoteListViewModel);
        Appl.Services.add('ManageReleaseNoteViewModel', ManageReleaseNoteViewModel);
    }
}