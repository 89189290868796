import React from 'react';
import './RapCalculatorView.css';
import { observer } from 'mobx-react';
import { Link, useNavigate } from 'react-router-dom';
import { CheckboxChangeEvent } from 'primereact/checkbox';
import RapCalculatorViewModel from './RapCalculatorViewModel';
import useAsyncEffect from 'use-async-effect';
import RapModule from '../RapModule';
import { Appl } from '../../../../core/Appl';
import { Button, Input, InputType, View, ViewHeader, InputCalendar, InputOptionBox, InputDropdown } from '../../../../core/components/Index';
import Helper from '../../../../core/services/Helper';
import { AppPermissions } from '../../../../core/services/AppPermissions';

export const RapCalculatorView: React.FC = observer(() => {
  const vm = Appl.Services.get<RapCalculatorViewModel>('RapCalculatorViewModel');
  vm.navigate = useNavigate();

  useAsyncEffect(async () => {
    await vm.initAsync();
  }, []);
  return (
    <>
      <View id="RapCalculatorView"
        authKey={AppPermissions.Calculator_21_20_VIEW}
        showMenuBar={Appl.User.isAuthenticated}
      // menuItems={RapModule.MenuItems}
      >
        <ViewHeader title={vm.pageTitle} description={vm.pageDescription} />
        <div className="space-y-10 mt-4">
          {/* <h2 className="text-22px font-semibold mb-6 text-black">Member Information</h2> */}
          {/* <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4 mb-6">
            <div>
              <Input
                id="mbrEmployeeId"
                value={vm.model.mbrEmployeeID}
                inputType={InputType.Text}
                onChange={(e) => {
                  vm.onMemberInfoChange(e, 'mbrEmployeeId');
                }}
                label="Employee Id"
                required={true}
                maxLength={50}
              />
            </div>
            <div>
              <Input
                id="mbrSSN"
                value={vm.model.mbrSSN}
                inputType={InputType.Text}
                onChange={(e) => {
                  vm.onMemberInfoChange(e, 'mbrSSN');
                }}
                label="Social Security"
                required={true}
                maxLength={10}
              />
            </div>
            <div>
              <Input
                id="mbrLastName"
                value={vm.model.mbrLastName}
                inputType={InputType.Text}
                onChange={(e) => {
                  vm.onMemberInfoChange(e, 'mbrLastName');
                }}
                label="Last Name"
                required={true}
                maxLength={200}
              />
            </div>
            <div>
              <Input
                id="mbrFirstName"
                value={vm.model.mbrFirstName}
                inputType={InputType.Text}
                onChange={(e) => {
                  vm.onMemberInfoChange(e, 'mbrFirstName');
                }}
                label="First Name"
                required={true}
                maxLength={200}
              />
            </div>
          </div> */}
          {/* <hr /> */}
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-4 mb-10">
            <div>
              <InputDropdown
                id="retirementType"
                value={vm.model.retirementType}
                options={[{ value: 'Service Retirement (SR)', key: 'SR' }, { value: 'Non Service Connected Disability Retirement (NSCD)', key: 'NSCD' }, { value: 'Service Connected Disability Retirement (SCD)', key: 'SCD' }, { value: 'Death Retirement (D)', key: 'D' }]}
                optionValue="key"
                optionLabel="value"
                label="Retirement Type"
                placeholder='Type'
                required={true}
                onChange={(e) => vm.onInputChange(e, 'retirementType', vm.model)}
                style={{ width: '300px' }}
              />
            </div>
            <div>
              <InputCalendar
                id="retirementDate"
                value={vm.model.retirementDate}
                label="Retirement Date (mm/dd/yyyy)"
                onChange={(e) => {
                  vm.onInputChange(e, 'retirementDate', vm.model);
                }}
                required={true}
              // maxDate={new Date()}
              // className="w-full"
              />
            </div>
            <div>
              <Input
                id="retirementDesc"
                value={vm.model.desc}
                inputType={InputType.Text}
                onChange={(e) => {
                  vm.onMemberInfoChange(e, 'retirementDesc');
                }}
                label="Description"
                required={false}
                maxLength={200}
                width='300px'
              />
            </div>
            <div>
              <InputDropdown
                id="retirementPlan"
                value={vm.model.retirementPlan}
                options={[{ value: 'One', key: '1' }, { value: 'Two', key: '2' }, { value: 'Three', key: '3' },]}
                optionValue="key"
                optionLabel="value"
                label="Retirement Plan"
                placeholder='Plan/Tier/Allocation'
                required={true}
                onChange={(e) => vm.onInputChange(e, 'retirementPlan', vm.model)}
                style={{ width: '300px' }}
              />
            </div>
            <div>
              <Input
                id="mbrName"
                value={vm.model.mbrName}
                inputType={InputType.Text}
                onChange={(e) => {
                  vm.onMemberInfoChange(e, 'mbrName');
                }}
                label="Member Name"
                required={false}
                maxLength={20}
                width='300px'
              />
            </div>
            <div>
              <InputCalendar
                id="mbrDOB"
                value={vm.model.mbrDOB}
                label="Member Date of Birth (mm/dd/yyyy)"
                onChange={(e) => {
                  vm.onInputChange(e, 'mbrDOB', vm.model);
                }}
                required={true}
                maxDate={new Date()}
              // className="w-full"
              />
            </div>
            <div>
              <Input
                id="bnfName"
                value={vm.model.bnfName}
                inputType={InputType.Text}
                onChange={(e) => {
                  vm.onMemberInfoChange(e, 'bnfName');
                }}
                label="Beneficiary Name"
                required={false}
                maxLength={200}
              />
            </div>
            <div>
              <InputCalendar
                id="bnfDOB"
                value={vm.model.bnfDOB}
                label="Benefeciary Date of Birth (mm/dd/yyyy)"
                onChange={(e) => {
                  vm.onInputChange(e, 'bnfDOB', vm.model);
                }}
                required={true}
                maxDate={new Date()}
              // className="w-full"
              />
            </div>
            <div>
              <Input
                id="bnfrelation"
                value={vm.model.bnfRelation}
                inputType={InputType.Text}
                onChange={(e) => {
                  vm.onMemberInfoChange(e, 'bnfrelation');
                }}
                label="Relationship"
                required={true}
                maxLength={200}
              />
            </div>
            <div>
              <Input
                id="repUnit"
                value={vm.model.representativeUnit}
                inputType={InputType.Text}
                onChange={(e) => {
                  vm.onMemberInfoChange(e, 'repUnit');
                }}
                label="Representative Unit"
                required={false}
                maxLength={200}
              />
            </div>
            <div className="grid grid-cols-4 md:grid-cols-4 gap-40 mt-4 mb-1">
              <div>
                <InputOptionBox
                  inputId="unmodifiedContinuance"
                  label="Eligible for Unmodified Continuance"
                  className="mt-1"
                  checked={vm.unmodifiedContinuanceSelected}
                  onChange={(e: CheckboxChangeEvent) => vm.unmodifiedContinuanceChange(e)}
                />
              </div>
              <div>
                <InputOptionBox
                  inputId="eligibilityOverride"
                  label="Eligibility Override"
                  className="mt-1"
                  checked={vm.eligibilityOverrideSelected}
                  onChange={(e: CheckboxChangeEvent) => vm.eligibilityOverrideChange(e)}
                />
              </div>
              <div>
                <InputOptionBox
                  inputId="option2or3"
                  label="Eligible for Option 2/Option 3 Continuance"
                  className="mt-1"
                  checked={vm.option2or3Selected}
                  onChange={(e: CheckboxChangeEvent) => vm.option2or3Change(e)}
                />
              </div>
              <div>
                <InputOptionBox
                  inputId="mortalityOverride"
                  label="Mortality Override"
                  className="mt-1"
                  checked={vm.mortalityOverrideSelected}
                  onChange={(e: CheckboxChangeEvent) => vm.mortalityOverrideOverrideChange(e)}
                />
              </div>
            </div>
          </div>
          <hr />
          <h2 className="text-22px font-semibold mb-6 text-black">Employee Contribution</h2>
          <div className="grid grid-cols-1 md:grid-cols-5 gap-4 mt-6 mb-4">
            <div>
              <Input
                id="regular"
                value={vm.model.regular}
                inputType={InputType.Number}
                onChange={(e) => {
                  vm.onMemberInfoChange(e, 'regular');
                }}
                label="Regular"
                required={false}
                maxLength={20}
              />
            </div>
            <div>
              <Input
                id="col"
                value={vm.model.col}
                inputType={InputType.Number}
                onChange={(e) => {
                  vm.onMemberInfoChange(e, 'col');
                }}
                label="COL"
                required={false}
                maxLength={20}
              />
            </div>
            <div>
              <Input
                id="finalComp"
                value={vm.model.finalComp}
                inputType={InputType.Number}
                onChange={(e) => {
                  vm.onMemberInfoChange(e, 'finalComp');
                }}
                label="Final Comp"
                required={true}
                maxLength={20}
              />
            </div>
            <div>
              <Input
                id="SalDeathBen"
                value={vm.model.salaryDeathBenefitComp}
                inputType={InputType.Number}
                onChange={(e) => {
                  vm.onMemberInfoChange(e, 'SalDeathBen');
                }}
                label="Salary Death Benefit Comp"
                required={false}
                maxLength={200}
              />
            </div>
            <div>
              <Input
                id="integratedSC"
                value={vm.model.integratedServiceCredits}
                inputType={InputType.Number}
                onChange={(e) => {
                  vm.onMemberInfoChange(e, 'integratedSC');
                }}
                label="Integrated Service Credits"
                required={true}
                maxLength={20}
              />
            </div>
            <div>
              <Input
                id="integratedSL"
                value={vm.model.integratedSickLeave}
                inputType={InputType.Number}
                onChange={(e) => {
                  vm.onMemberInfoChange(e, 'integratedSL');
                }}
                label="Sick Leave (Integrated)"
                required={true}
                maxLength={20}
              />
            </div>
            <div>
              <Input
                id="nonintegratedSC"
                value={vm.model.nonintegratedServiceCredits}
                inputType={InputType.Number}
                onChange={(e) => {
                  vm.onMemberInfoChange(e, 'nonintegratedSC');
                }}
                label="Non-Integrated Service Credits"
                required={true}
                maxLength={20}
              />
            </div>
            <div>
              <Input
                id="nonintegratedSL"
                value={vm.model.nonintegratedSickLeave}
                inputType={InputType.Number}
                onChange={(e) => {
                  vm.onMemberInfoChange(e, 'nonintegratedSL');
                }}
                label="Sick Leave (Non-Integrated)"
                required={true}
                maxLength={20}
              />
            </div>
            <div>
              <Input
                id="tmp62"
                value={vm.model.tempAnnuity62}
                inputType={InputType.Number}
                onChange={(e) => {
                  vm.onMemberInfoChange(e, 'tmp62');
                }}
                label="Temporary Annuity (62)"
                required={false}
                maxLength={200}
              />
            </div>
            <div>
              <Input
                id="tmp65"
                value={vm.model.tempAnnuity65}
                inputType={InputType.Number}
                onChange={(e) => {
                  vm.onMemberInfoChange(e, 'tmp65');
                }}
                label="Temporary Annuity (65)"
                required={false}
                maxLength={20}
              />
            </div>
          </div>
          <hr />
          <div className="flex items-center justify-center gap-x-6 my-6">
            <Button title="Calculate" disabled={vm.enableCalculateButton} onClick={async () => await vm.calculateRAPAsync()} onChange={(e) => {
              vm.onMemberInfoChange(e, "Calculate");
            }} />
            <Button title="Back" severity="secondary" onClick={() => vm.navigate(-1)} />
          </div>
          <div>
            {Helper.formattedHtml(vm.rapResults)}
          </div>
        </div>
      </View>
    </>
  );
});
