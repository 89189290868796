import React from 'react';
import useAsyncEffect from 'use-async-effect';
import { observer } from 'mobx-react';
import UserListViewModel from './UserListViewModel';
import { Appl } from '../../../../../core/Appl';
import { UserListModel } from './UserListModel';
import Helper, { formatDate } from '../../../../../core/services/Helper';
import { DataGrid, Icon, SearchToolbar, View, ViewHeader } from '../../../../../core/components/Index';
import { Column } from 'primereact/column';
import { Link, useNavigate } from 'react-router-dom';
import { AppPermissions } from '../../../../../core/services/AppPermissions';

const UserListView: React.FC = observer(() => {
  const vm = Appl.Services.get<UserListViewModel>('UserListViewModel');
  vm.navigate = useNavigate();
  useAsyncEffect(async () => {
    await vm.getPagedAsync();
  }, []);

  const lastLoginDateTemplate = (row: UserListModel) => {
    return formatDate(row.lastLoginDate);
  };

  const actionTemplate = (row: UserListModel) => {
    let editLink = `/account/user/${row.usrId}`;
    return (
      <div className="flex">
        <Link to={editLink} title="Edit">
          <Icon name="file-pen-line" size={24} className="link me-2" title="Edit" />
        </Link>
        {/* <span className="link"
          onClick={async () => vm.navigate(`/account/user/${row.usrId}/permission`)}
          title='Permissions'>
          <Icon name="shield-check" size={24} color="black" className="me-2" title="Permissions" />
        </span> */}
      </div>
    );
  };

  return <>
    <View id="UserListView" pageTitle={vm.pageTitle}
      authKey={AppPermissions.Users_17_16_VIEW}
      showMenuBar={false}>
      <ViewHeader title={vm.pageTitle} description={vm.pageDescription}>
        <SearchToolbar
          filterPlaceholder="ID / Name / Phone / Email"
          filter={vm.searchRequest?.filter}
          onFilterChange={(e) => vm.onInputChange(e, 'filter', vm.searchRequest)}
          onRefresh={async () => await vm.getPagedAsync()}
          addNewVisible={true}
          onAddNew={() => vm.navigate('/account/user/0')}
          refreshVisible={false}
          showButton={false}
          // run search on input change
          searchOnInputChange={true}
        />
      </ViewHeader>
      <DataGrid
        pageSize={vm.pageSize}
        dataSource={vm.items}
        totalRowsCount={vm.totalRowsCount}
        searchRequest={vm.searchRequest}
        onRefresh={() => vm.getPagedAsync()}
      >
        <Column field="usrId" header="ID" sortable={true} hidden={false} />
        <Column field="userDisplyName" header="NAME" sortable={true} />
        <Column field="usrEmailId" header="EMAIL" sortable={true} />
        <Column field="usrRole" header="ROLE" sortable={true} />
        <Column field="phoneNbr" header="PHONE" sortable={true} body={(row: UserListModel) => Helper.formatPhone(row.phoneNbr!)} />
        <Column field="lastLoginDate" header="LAST LOGIN DATE" sortable={true} body={lastLoginDateTemplate} />
        <Column body={actionTemplate} header="Manage" />
      </DataGrid>
    </View>
  </>;
});

export default UserListView;
