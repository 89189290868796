import { Service } from 'typedi';
import { BaseService, IBaseService, ServerResponse } from '../../../core/services/BaseService';
import { Appl } from '../../../core/Appl';
import { RaPMbrLookupModel } from './calculator/RapCalculatiorModel';

export interface IRapService extends IBaseService {
  //rfrefer
  getRAPEstimateAsync(model: any): Promise<ServerResponse>;
}

@Service() // eslint-disable-next-line
export class RapService extends BaseService implements IRapService {
  constructor() {
    super(`${Appl.Setting.Services.RapServiceUrl}`);
  }


  public async getRAPEstimateAsync(model:any): Promise<ServerResponse>{ 
    try {
      const response = await this.client.post(`/RAP`, model);
      return this.getResponse(response);
    } catch (error: unknown) {
      return await this.getErrorResponse(error);
    }
  }
}
