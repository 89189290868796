import React from 'react';
import './DashboardView.css';
import { observer } from 'mobx-react';
import { Button, Icon, View, ViewHeader } from '../../../core/components/Index';
import { Link, useNavigate } from 'react-router-dom';
import DashboardViewModel from './DashboardViewModel';
import useAsyncEffect from 'use-async-effect';
import { Appl } from '../../../core/Appl';
import SraModule from '../../admin/sra/SraModule';
import { Role } from '../../../core/services/Enums';
import { SubmissionStatus } from '../../admin/sra/SraEnums';
import { AppPermissions } from '../../../core/services/AppPermissions';

export const DashboardView: React.FC = observer(() => {
  const vm = Appl.Services.get<DashboardViewModel>('DashboardViewModel');
  vm.navigate = useNavigate();

  useAsyncEffect(async () => {
    await vm.initAsync();
  }, []);

  return (
    <>
      <View id="DashboardView" pageTitle={vm.pageTitle}
        authKey={AppPermissions.AuthenticatedUser}
        showMenuBar={false}
        menuItems={SraModule.MenuItems()}>
        <ViewHeader title={vm.pageTitle} description={vm.pageDescription} helpPageId="" />
        <div className="grid grid-cols-3 gap-4">
          {(Appl?.User?.hasPermission(AppPermissions.ORAIntake_4_3_VIEW)) && (
            <div className="colour-1 p-6 rounded-xl h-full">
              <div className="content">
                <h5>
                  <Icon name="file-pen-line" title="" color="white" /> VIEW SUBMISSIONS
                </h5>
                <p>View & Approve Retirement Application Forms.</p>
              </div>
              <div className="text-end content-footer">
                <Link to="/sra/retiree" className="btn btn-primary text-end">
                  View
                </Link>
              </div>
            </div>
          )}
          {(Appl.User.hasRole(Role.Member) && Appl.Setting.EnableAllFeatures) && (
            <div className="colour-1 p-6 rounded-xl h-full">
              <>
                {(vm.submission === undefined) &&
                  <>
                    <div className="content">
                      <h5>
                        <Icon name="file-pen-line" title="" color="white" />APPLY FOR RETIREMENT
                      </h5>
                      <p>
                        Start submitting your Retirement Application Form.
                      </p>
                    </div>
                    <div className="text-end content-footer">
                      <Button title="Start Submission" onClick={() => vm.navigate(`/sra/retiree/application/${Appl.User.userId}/getting-started`)} />
                    </div>
                  </>
                }
                {vm.submission?.lovSubmissionStatusId === SubmissionStatus.Draft &&
                  <>
                    <div className="content">
                      <h5>
                        <Icon name="file-pen-line" title="" color="white" />SUBMISSION IS DRAFT
                      </h5>
                      <p>
                        Manage your Retirement Application Form.
                      </p>
                    </div>
                    <div className="text-end content-footer">
                      <Button title="View Submission" onClick={() => vm.navigate(`/sra/retiree/application/${Appl.User.userId}`)} />
                    </div>
                  </>
                }
                {vm.submission?.lovSubmissionStatusId === SubmissionStatus.Submitted &&
                  <>
                    <div className="content">
                      <h5>
                        <Icon name="check-check" title="" color="white" />APPLICATION SUBMITTED
                      </h5>
                      <p>
                        Congratulations! You have completed submission of your retirement application.
                      </p>
                    </div>
                    <div className="text-end content-footer">
                      {/* <Button title="View / Print" onClick={async () => await vm.showPrintDialog()} /> */}
                    </div>
                  </>
                }
                {vm.submission?.lovSubmissionStatusId === SubmissionStatus.InProgress &&
                  <>
                    <div className="content">
                      <h5>
                        <Icon name="cog" title="" color="white" />APPLICATION UNDER REVIEW
                      </h5>
                      <p>
                        We have received your application with reference #<b>{vm.submission?.submissionId}</b>. We are in the process of reviewing your application and will notify you when it is done.
                      </p>
                    </div>
                    <div className="text-end content-footer">
                      {/* <Button title="View / Print" onClick={async () => await vm.showPrintDialog()} /> */}
                    </div>
                  </>
                }
                {vm.submission?.lovSubmissionStatusId === SubmissionStatus.Completed &&
                  <>
                    <div className="content">
                      <h5>
                        <Icon name="shield-check" title="" color="white" />APPLICATION SUBMISSION COMPLETED
                      </h5>
                      <p>
                        We have received your application with reference #<b>{vm.submission?.submissionId}</b>. Your application submission process completed.
                      </p>
                    </div>
                    <div className="text-end content-footer">
                      {/* <Button title="View / Print" onClick={async () => await vm.showPrintDialog()} /> */}
                    </div>
                  </>
                }
              </>
            </div>
          )}
          {Appl?.User?.hasPermission(AppPermissions.DeathNotifications_8_7) && (
            <div className="colour-2 p-6 rounded-xl h-full">
              <div className="content">
                <h5>
                  <Icon name="clipboard-list" title="" color="white" /> VIEW DEATH NOTIFICATIONS
                </h5>
                <p>View & Approve Death Notifications.</p>
              </div>
              <div className="text-end content-footer">
                <Link to="/members/death-report" className="btn btn-primary text-end">
                  View
                </Link>
              </div>
            </div>
          )}
          {Appl?.User?.hasPermission(AppPermissions.Queue_10_9_VIEW) && (
            <div className="colour-3 p-6 rounded-xl h-full">
              <div className="content">
                <h5>
                  <Icon name="clipboard-list" title="" color="white" /> VIEW SECURE FILE UPLOADS
                </h5>
                <p>View & Approve Secure File uploads from members.</p>
              </div>
              <div className="text-end content-footer">
                <Link to="/members/upload-requests" className="btn btn-primary text-end">
                  View
                </Link>
              </div>
            </div>
          )}
          {Appl?.User?.hasPermission(AppPermissions.ChangeofAddress_5_5) && <div>
            <div className="colour-4 p-6 rounded-xl h-full">
              <div className="content">
                <h5>
                  <Icon name="list-checks" title="" color="white" /> CHANGE OF ADDRESS
                </h5>
                <p>View & Approve Change of Address Requests.</p>
              </div>
              <div className="text-end content-footer">
                <Button title="Request" onClick={() => vm.navigate(`/members/change-of-address`)} />
              </div>
            </div>
          </div>
          }
          {(Appl.User.hasRole(Role.Member) && Appl.Setting.EnableAllFeatures) && <div>
            <div className="colour-4 p-6 rounded-xl h-full">
              <div className="content">
                <h5>
                  <Icon name="list-checks" title="" color="white" /> CHANGE OF ADDRESS
                </h5>
                <p>Request for Change of Address.</p>
              </div>
              <div className="text-end content-footer">
                <Button title="Request" onClick={() => vm.navigate(`/members/change-of-address/${Appl.User.userId}`)} />
              </div>
            </div>
          </div>
          }
        </div>
      </View>
    </>
  );
});
