import React from 'react';
import { observer } from 'mobx-react';
import { Button, DataGrid, InputCalendar, InputDropdown, View, ViewHeader } from '../../../../../core/components/Index';
import { useNavigate } from 'react-router-dom';
import useAsyncEffect from 'use-async-effect';
import { Column } from 'primereact/column';
import { NotificationListModel } from './NotificationListModel';
import { Appl } from '../../../../../core/Appl';
import NotificationListViewModel from './NotificationListViewModel';
import Helper, { formatDateTime } from '../../../../../core/services/Helper';
import { Dialog } from 'primereact/dialog';
import { AppPermissions } from '../../../../../core/services/AppPermissions';

export const NotificationListView: React.FC = observer(() => {
  const vm = Appl.Services.get<NotificationListViewModel>('NotificationListViewModel');
  vm.navigate = useNavigate();
  useAsyncEffect(async () => {
    await vm.initAsync();
    await vm.getPagedAsync();
  }, []);


  const statusTemplate = (row: NotificationListModel) => {
    const textClassName = 'bg-white-100 text-black tracking--1_5percent px-1 py-0.5 rounded';
    let message = "Pending";
    if (row.statusCode === 'P') {
      message = "Pending";
    } else if (row.statusCode === 'I') {
      message = "In Progress";
    } else if (row.statusCode === 'S') {
      message = "Sent";
    } else if (row.statusCode === 'E') {
      message = "Error";
    }
    return (
      <span
        className={textClassName}
        title={message}
        style={{ cursor: 'pointer' }}
        onClick={async () => await vm.showNotificationBodyDialog(row)}>
        {message}
      </span>
    );
  };

  const eventDescription = (row: NotificationListModel) => {
    return (
      <>{vm.lovEventList.find(o => o.key === row.eventCd)?.value}</>
    );
  }
  return (
    <>
      <View id="NotificationListView" pageTitle={vm.pageTitle}
        authKey={AppPermissions.EventNotifications_15_14_VIEW}
        showMenuBar={false}>
        <ViewHeader title={vm.pageTitle} description={vm.pageDescription}>
          {/* <div> */}
          <div>
            <InputDropdown
              id="eventCd"
              value={vm.eventCd}
              options={vm.lovEventList}
              optionValue="key"
              optionLabel="value"
              label="&nbsp;&nbsp;&nbsp;&nbsp;Event Name"
              placeholder='Select Event Name'
              style={{ width: '275px' }}
              className="w-full me-4 ms-4"
              required={false}
              onChange={
                (e) => {
                  vm.onInputChange(e, "eventCd", vm);
                }
              }
            />
          </div>
          <div>
            <InputCalendar
              id="startDate"
              value={vm.startDate as string & Date}
              label="From Date"
              onChange={(e) => {
                vm.onInputChange(e, 'startDate', vm);
              }}
              required={false}
              maxDate={new Date()}
            />
          </div>
          <div>
            <InputCalendar
              id="endDate"
              value={vm.endDate as string & Date}
              label="To Date"
              onChange={(e) => {
                vm.onInputChange(e, 'endDate', vm);
              }}
              required={false}
              maxDate={new Date()}
            />
          </div>
          <div>
            <Button title="Search" className='mt-6' onClick={async () => await vm.getPagedAsync()} />
          </div>
          {/* </div> */}
        </ViewHeader>
        <DataGrid
          pageSize={vm.pageSize}
          dataSource={vm.items}
          totalRowsCount={vm.totalRowsCount}
          searchRequest={vm.searchRequest}
          onRefresh={() => vm.getPagedAsync()}>
          <Column field="eventNotificationId" header="ID" sortable={true} />
          <Column field="eventCd" header="EVENT DESCRIPTION" body={eventDescription} sortable={true} />
          <Column field="eventToAddress" header="SENT TO" sortable={true} />
          <Column field="eventHeader" header="SUBJECT" sortable={true} />
          <Column field="notificationSentDate" header="DATE SENT" body={(row: NotificationListModel) => formatDateTime(row.notificationSentDate)} sortable={true} />
          <Column field="statusCode" header="STATUS" body={statusTemplate} sortable={true} />
        </DataGrid>
        <Dialog
          header={`Notification #: ${vm.selectedRow?.eventNotificationId}`}
          visible={vm.dialogVisible}
          closable={true}
          modal
          blockScroll={true}
          className="max-w-5xl !w-full !h-auto"
          contentClassName='overflow-y-auto'
          onHide={() => vm.hideDialog()}>
          <div className="flex flex-col flex-wrap md:flex-nowrap  gap-y-2 tracking--1_5percent 
            [&_p]:mb-4 [&_p]:leading-relaxed 
            [&_h1]:text-2xl [&_h1]:font-bold [&_h1]:mb-4  
            [&_h2]:text-xl [&_h2]:font-semibold [&_h2]:mb-3  
            [&_h3]:text-lg [&_h3]:font-medium [&_h3]:mb-2  
            [&_a]:text-primary-500 [&_a]:underline  [&_a]:font-medium [&_a]:hover:text-primary-700
            [&_ul]:list-disc [&_ul]:ml-6 [&_ul]:mb-4 [&_ul_li]:mb-2
            [&_ol]:list-decimal [&_ol]:ml-6 [&_ol]:mb-4 [&_ol_li]:mb-2
            [&_blockquote]:pl-4 [&_blockquote]:border-l-4 [&_blockquote]:border-gray-300 [&_blockquote]:italic [&_blockquote]:my-4
            [&_pre]:bg-gray-100 [&_pre]:p-4 [&_pre]:rounded [&_pre]:mb-4
            [&_code]:bg-gray-100 [&_code]:px-1 [&_code]:py-0.5 [&_code]:rounded
            [&_a.btn.primary]:text-white [&_a.btn.primary]:no-underline
            [&_hr]:my-6  ">
            <p><b>Subject:</b> {vm.selectedRow?.eventHeader}</p>
            <p className='mt-3'><b>Body:</b></p>
            <p className='mt-2'>
              {vm.selectedRow && Helper.formattedHtml(vm.selectedRow?.eventBody)}
              {vm.selectedRow && vm.selectedRow.statusCode === "E" &&
                <>
                  <p className='mt-3'><b>Error:</b></p>
                  <p className='mt-2'>{vm.selectedRow && Helper.formattedHtml(vm.selectedRow?.successFailureMsg)}</p>
                </>
              }
            </p>
          </div>
        </Dialog>
      </View>
    </>
  );
});
