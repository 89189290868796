import {
    AuthenticationResult, Configuration, EventMessage, EventType,
    IPublicClientApplication, PublicClientApplication
} from '@azure/msal-browser';
import { IUserContext, Permission, UserInfo } from './IUserContext';
import { UserContextBase } from './UserContextBase';
import { ServerResponse } from './BaseService';
import { Appl } from '../Appl';
import { jwtDecode } from 'jwt-decode';
export class UserContextAzure extends UserContextBase implements IUserContext {

    private static _instance: UserContextAzure;
    private static _msalInstance?: IPublicClientApplication;
    public static get instance() {
        if (!this._instance) {
            this._msalInstance = this.getMsalInstance()
            return this._instance = new UserContextAzure();
        }
        return this._instance;
    }

    private static scope?: { scopes: string[] };

    public async initAsync(): Promise<void> {
        let sessionStarted = this._cache.getCookie<boolean>("userSession");
        if (!sessionStarted) {
            this._cache.removeAllCache();
            this._cache.addOrUpdateCookie<boolean>('userSession', true);
            window.location.reload();
        }
        await UserContextAzure._msalInstance?.initialize();
        // UserContextAzure._msalInstance?.enableAccountStorageEvents();
        UserContextAzure._msalInstance?.addEventCallback(async (event: EventMessage) => {
            if (event.eventType === EventType.LOGIN_SUCCESS ||
                event.eventType === EventType.SSO_SILENT_SUCCESS) {
                const payload = event.payload as AuthenticationResult;
                UserContextAzure._msalInstance?.setActiveAccount(payload.account);
                let result = await this.storeUserInfo(payload.accessToken, true);
                if (result.success) {
                    console.log(`3. Sign In to Azure - ${event.eventType} ${payload.accessToken}`);
                    if (event.eventType === EventType.LOGIN_SUCCESS) {
                        window.location.assign(Appl.Setting.LoginSuccessNavigateUrl!);
                    }
                } else {
                    Appl.Error.handle(result.data)
                }
                // UserContextAzure._msalInstance?.setActiveAccount(account);
            } else if (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
                const payload = event.payload as AuthenticationResult;
                await this.storeUserInfo(payload.accessToken, false);
            }
            else if (event.eventType === EventType.LOGIN_FAILURE) {
                Appl.MessageBox.show(event.error?.message!);
            }
            else {
                if (event.error) {
                    Appl.MessageBox.show(event.error?.message);
                }
            }
        });
    }

    public async signInAsync(username?: string, password?: string): Promise<ServerResponse> {
        console.log(`1. Sign In to AzureAD`);
        const usePopup = false;
        Appl.Cache.addOrUpdate("AuthProvider", "AzureAD");
        try {
            let instance = UserContextAzure._msalInstance;
            await instance?.initialize();
            if (usePopup) {
                let authResult = await instance?.loginPopup(UserContextAzure.scope)
                if (authResult?.account) {
                    return await this.storeUserInfo(authResult?.accessToken, true);
                } else {
                    return {
                        success: false,
                        data: 'Error Logging...'
                    };
                }
            }
            let result = await instance?.handleRedirectPromise();
            if (result?.account.username === undefined) {
                await instance?.loginRedirect(UserContextAzure.scope);
            }
            console.log(`1.1. After Sign In to Azure`);
            return {
                success: true,
                data: ''
            };
        } catch (e: any) {
            console.log(`1.2. Sign In to Azure Error: ${e}`);
            return {
                success: false,
                data: e
            };
        }
    }

    public async refreshTokenAsync(): Promise<void> {
        let instance = UserContextAzure._msalInstance;
        if (instance) {
            var currentAccount = instance.getActiveAccount();
            if (currentAccount) {
                let authSetting = Appl.Setting.AuthProviders?.find(o => o.Provider === "AzureAD");
                let scopes = authSetting ? authSetting.Scopes : [];
                const request = {
                    scopes: scopes,
                    account: currentAccount,
                    forceRefresh: false,
                };
                // Silently acquires an access token which is then attached to a request for Microsoft Graph data
                console.log(`2.4 Aquiring the Access Token Silently`);
                let authResult: AuthenticationResult | undefined = await instance?.acquireTokenSilent(request);
                console.log(`2.4.1 Aqquired the Access Token with State: ${authResult?.state} and will expire on ${authResult.expiresOn}`);
                // console.log(`Token: ${authResult?.accessToken}`)
            }
        }
    }
    public async signOutAsync(): Promise<void> {
        Appl.Cache.removeAllCache();
        let instance = UserContextAzure._msalInstance;
        await instance?.handleRedirectPromise();
        await instance?.logout();
    }

    public getUser(): UserInfo {
        const user = this._cache.get<UserInfo>(this.userInfoKey) as UserInfo;
        if (user) {
            return user;
        }
        console.log(`2.1 Token Not Exists`);
        let instance = UserContextAzure._msalInstance;
        instance?.initialize().then(async () => {
            await instance?.handleRedirectPromise();
            const accounts = instance?.getAllAccounts();
            if (accounts?.length === 0) {
                console.log(`2.2 Account Not Exists`);
                return user;
            }
            if (accounts) {
                console.log(`2.3 Account Exists`);
                await this.refreshTokenAsync();
            }
        })
        return user;
    }

    private static getMsalInstance(): IPublicClientApplication {
        let authSetting = Appl.Setting.AuthProviders?.find(o => o.Provider === "AzureAD");
        this.scope = {
            scopes: authSetting?.Scopes!
        }
        const msalConfig: Configuration = {
            auth: {
                clientId: authSetting?.ClientId!,
                authority: authSetting?.AuthorityUrl,
                redirectUri: authSetting?.LoginRedirectUrl,
                postLogoutRedirectUri: authSetting?.LogoutUrl,
                navigateToLoginRequestUrl: false
            },
            cache: {
                cacheLocation: 'localStorage',
                storeAuthStateInCookie: false,
            }
        }
        let msalInstance = new PublicClientApplication(msalConfig);
        return msalInstance;
    }

    private async storeUserInfo(accessToken: string, requiresPermissions: boolean): Promise<any> {
        let user = this._cache.get<UserInfo>(this.userInfoKey) as UserInfo;
        if (!user) {
            let accountInfo = jwtDecode(accessToken) as any;
            user = {
                userId: accountInfo.unique_name,
                email: accountInfo.unique_name,
                displayName: `${accountInfo.given_name} ${accountInfo.family_name}`,
                photo: undefined,
                roles: new Array<string>,
                permissions: new Array<Permission>, accessToken: undefined
            } as UserInfo;
        }
        user.accessToken = accessToken;
        if (!requiresPermissions) {
            await Appl.User.saveUserAsync(user);
            return {
                success: true,
                data: ''
            };
        }
        if (requiresPermissions) {
            let authSetting = Appl.Setting.AuthProviders?.find(o => o.Provider === "AzureAD");
            // const meUrl = `${authSetting?.UserInfoUrl!}/${user.userId}`
            const request = new Request(authSetting?.UserInfoUrl!, {
                method: "GET",
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            try {
                const meResponse = await fetch(request)
                if (meResponse.ok) {
                    let userData = await meResponse.json();
                    await Appl.User.saveUserAsync(userData);
                    return {
                        success: true,
                        data: ''
                    };
                }
                else {
                    let error = await meResponse.text();
                    return {
                        success: false,
                        data: error
                    };
                }
            } catch (ex: any) {
                console.log(ex);
                return {
                    success: false,
                    data: ex
                };
            }
        }
    }
}

