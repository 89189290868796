import { observer } from 'mobx-react';
import ApplyViewModel from './ApplyViewModel';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useAsyncEffect from 'use-async-effect';
import { Button, PdfViewer, View, ViewHeader } from '../../../../../core/components/Index';
import { Appl } from '../../../../../core/Appl';
import { AppPermissions } from '../../../../../core/services/AppPermissions';
const PrintApplication = observer(() => {

  const vm = Appl.Services.get<ApplyViewModel>('ApplyViewModel');
  vm.navigate = useNavigate();
  let { memberId, submissionId } = useParams();
  useAsyncEffect(async () => {
    await vm.showPrintDialog(submissionId as unknown as number);
  }, []);
  return (
    <>
      <View id="PrintApplication" pageTitle="Retirement Application Packet Review"
        authKey={AppPermissions.AuthenticatedUser}
        showMenuBar={false}
      >
        <ViewHeader title="Retirement Application Packet Review" description={vm.pageDescription} helpPageId="">
          <div className='space-x-4'>
            <Button label='Looks Good' onClick={async () => await vm.updateSelfReviewStatusAsync("Y")} />
            <Button label='Make Changes' severity="warning" outlined onClick={() => Appl.MessageBox.show(
              <div>
                Select the section you wish to change. Once the section is complete, clicking <b>Save & Return to Review</b> will you return you here.
                <ul className='list-disc ml-4 mt-4 pb-6'>
                  <li><Link to='#' onClick={async (e: any) => await vm.navigateFromReviewStepAsync(e, `/sra/retiree/application/${vm.memberId}/select-date`)} className='text-green-900'>Retirement Date</Link></li>
                  <li><Link to='#' onClick={async (e: any) => await vm.navigateFromReviewStepAsync(e, `/sra/retiree/application/${vm.memberId}/your-information/address-information`)} className='text-green-900'>Address</Link></li>
                  <li><Link to='#' onClick={async (e: any) => await vm.navigateFromReviewStepAsync(e, `/sra/retiree/application/${vm.memberId}/your-information/contact-information`)} className='text-green-900'>Email/Phone Number</Link></li>
                  <li><Link to='#' onClick={async (e: any) => await vm.navigateFromReviewStepAsync(e, `/sra/retiree/application/${vm.memberId}/your-information/personal-status`)} className='text-green-900'>Personal Status</Link></li>
                  <li><Link to='#' onClick={async (e: any) => await vm.navigateFromReviewStepAsync(e, `/sra/retiree/application/${vm.memberId}/option-selection/select-your-option`)} className='text-green-900'>Option Selection</Link></li>
                  <li><Link to='#' onClick={async (e: any) => await vm.navigateFromReviewStepAsync(e, `/sra/retiree/application/${vm.memberId}/temporary-annuity`)} className='text-green-900'>Temporary Annuity</Link></li>
                  <li><Link to='#' onClick={async (e: any) => await vm.navigateFromReviewStepAsync(e, `/sra/retiree/application/${vm.memberId}/beneficiary-designation/beneficiary-information`)} className='text-green-900'>Beneficiary Designation</Link></li>
                  <li><Link to='#' onClick={async (e: any) => await vm.navigateFromReviewStepAsync(e, `/sra/retiree/application/${vm.memberId}/tax-withholding/federal-tax-withholding`)} className='text-green-900'>Tax Withholding Information</Link></li>
                  <li><Link to='#' onClick={async (e: any) => await vm.navigateFromReviewStepAsync(e, `/sra/retiree/application/${vm.memberId}/direct-deposit/direct-deposit-authorization`)} className='text-green-900'>Direct Deposit Information</Link></li>
                </ul>
              </div>
              , "Make Changes", '', "Close")} />
            <Button label='Cancel' outlined onClick={() => vm.navigate(`/sra/retiree/application/${vm.memberId}/review-application`)} />
          </div>
        </ViewHeader>
        {vm.afrFile && <PdfViewer data={vm.afrFile} />}
      </View >
    </>
  );
});
export default PrintApplication;



