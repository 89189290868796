import React from 'react';
import { observer } from 'mobx-react';
import { Button, DataGrid, Icon, InputDropdown, View, ViewHeader } from '../../../../../core/components/Index';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useAsyncEffect from 'use-async-effect';
import { Column } from 'primereact/column';
import { LovListModel } from './LovListModel';
import { Appl } from '../../../../../core/Appl';
import LovListViewModel from './LovListViewModel';
import Helper from '../../../../../core/services/Helper';
import { AppPermissions } from '../../../../../core/services/AppPermissions';

export const LovListView: React.FC = observer(() => {
  const vm = Appl.Services.get<LovListViewModel>('LovListViewModel');
  vm.navigate = useNavigate();
  let { type } = useParams();
  useAsyncEffect(async () => {
    await vm.initAsync(type as string);
    await vm.getPagedAsync();
  }, []);


  const actionTemplate = (row: LovListModel) => {
    let editLink = `/lov/${vm.tableName}/${row.id}`;
    return (
      <div className="flex">
        <Link to={editLink} title="Edit LOV">
          <Icon name="file-pen-line" size={24} className="link me-2" title="Edit" />
        </Link>
      </div>
    );
  };

  return (
    <>
      <View id="LovListView" pageTitle={vm.pageTitle}
        authKey={AppPermissions.LOVMaintenance_13_12_VIEW}
        showMenuBar={false}>
        <ViewHeader title={vm.pageTitle} description={vm.pageDescription}>
          <InputDropdown
            id="tableName"
            value={vm.tableName}
            options={vm.lovTableList}
            optionValue="key"
            optionLabel="value"
            label="LOV Table"
            placeholder='Select LOV Table'
            style={{ width: '375px' }}
            className="w-full me-4 ms-4"
            required={false}
            onChange={async (e) => await vm.onTableNameChange(e)}
          />
          <Button title="Add New" onClick={() => vm.navigate(`/lov/${vm.tableName}/0`)} />
        </ViewHeader>
        <DataGrid
          pageSize={vm.pageSize}
          pageDropdownNumbers={[1000]}
          dataSource={vm.items}
          totalRowsCount={vm.totalRowsCount}
          searchRequest={vm.searchRequest}
          onRefresh={() => vm.getPagedAsync()}>
          <Column field="id" header="ID" sortable={true} hidden={false} />
          <Column field="code" header="CODE" sortable={true} body={(row: LovListModel) => Helper.formattedHtml(row.code!)} />
          <Column field="description" header="DESCRIPTION" sortable={true} body={(row: LovListModel) => Helper.formattedHtml(row.description!)} />
          <Column body={actionTemplate} header="Manage" />
        </DataGrid>
      </View>
    </>
  );
});
