import React from 'react';
import './FooterView.css';
import { observer } from 'mobx-react';
import { Container } from '../../core/components/containers/Container';
import { Appl } from '../../core/Appl';

type FooterProps = {
  version?: string;
};

export const FooterView: React.FC<FooterProps> = observer(({ version = Appl.Setting.AppVersion }) => {
  return (
    <>
      <div className="bg-cream-50 py-2">
        <Container>
          <div className='justify-end flex items-center gap-2  text-15px'>
            <a className='underline hover:underline' href="https://www.scers.gov/about" target="_blank">About SCERS</a><p>|</p>
            <a className='underline hover:underline' href="https://my.scers.gov/termsandconditions" target="_blank">Terms and Conditions of Use</a><p>|</p>
            <a className='underline hover:underline' href="https://www.scers.gov/contact" target="_blank" >Contact Us</a><p>|</p>
            <a className='underline hover:underline' href="https://www.scers.gov/" target="_blank">scers.gov </a><p>|</p>
            <a className='underline hover:underline' href="/utility/release-notes" target="_blank">{version}</a>
          </div>
        </Container>
      </div>
    </>
  );
});
