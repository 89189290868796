import { Appl } from '../../../core/Appl';
import { AccountService } from './AccountService';
import LoginViewModel from './login/LoginViewModel';
import ProfileViewModel from './profile/ProfileViewModel';
import UserListViewModel from './user/list/UserListViewModel';
import UserViewModel from './user/addEdit/UserViewModel';
import RoleViewModel from './role/addEdit/RoleViewModel';
import RoleListViewModel from './role/list/RoleListViewModel';
import PermissionListViewModel from './role/permission/PermissionListViewModel';

export default class AccountModule {
  public static init(): void {
    Appl.Services.add('IAccountService', AccountService);
    Appl.Services.add('LoginViewModel', LoginViewModel);
    Appl.Services.add('ProfileViewModel', ProfileViewModel);
    Appl.Services.add('UserListViewModel', UserListViewModel);
    Appl.Services.add('UserViewModel', UserViewModel);
    Appl.Services.add('RoleListViewModel', RoleListViewModel);
    Appl.Services.add('RoleViewModel', RoleViewModel);
    Appl.Services.add('PermissionListViewModel', PermissionListViewModel);

  }
}
