import './Button.css';
import { ReactNode } from "react";
import { ButtonProps as ButtonPropsFromPrimeReact } from 'primereact/button';
import { Button as PrimeReactButton } from 'primereact/button';
import { Icon } from "../Icon";
import { Link } from "react-router-dom";

export enum ButtonType {
    Button = 'button',
    Submit = 'submit',
    Link = 'link',
}
export interface ButtonProps extends ButtonPropsFromPrimeReact {
    buttonType?: ButtonType;
    title?: string;
    isbusy?: boolean;
    icon?: ReactNode;
    iconColor?: string;
    url?: any;
    tooltip?: string;
}

export const Button: React.FC<ButtonProps> = ({ buttonType = ButtonType.Button, className = 'success', iconColor = 'black', ...props }) => {
    return (
        <>
            {(buttonType === ButtonType.Button || buttonType === ButtonType.Submit) && (
                <PrimeReactButton type={buttonType} className={`${className} ${props.isbusy ? 'disabled' : ''}`} {...props}>
                    {props.icon && !props.isbusy && <Icon name={props.icon as any} className="text-white mr-2" size={16} color={'currentColor'} />}
                    {props.isbusy && <Icon name="spinner" className="text-white mr-2" size={16} color={'currentColor'} />}
                    {props.title}
                </PrimeReactButton>
            )}
            {buttonType === ButtonType.Link && (
                <PrimeReactButton
                    severity="secondary"
                    outlined
                    className={`${className} ${props.isbusy ? 'disabled' : ''}`}
                    role="button"
                    {...(props as object)}>
                    <Link to={props.url}>
                        {props.icon && !props.isbusy && <Icon name={props.icon as any} className="me-2" size={16} color={iconColor} />}
                        {props.isbusy && <Icon name="spinner" className="me-2" size={16} color={iconColor} />}
                        {props.title}
                    </Link>
                </PrimeReactButton>
            )}
        </>
    );
};