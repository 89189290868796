import React from 'react';
import useAsyncEffect from 'use-async-effect';
import { observer } from 'mobx-react';
import PermissionListViewModel from './PermissionListViewModel';
import { Appl } from '../../../../../core/Appl';
import { Button, View, ViewHeader } from '../../../../../core/components/Index';
import { useNavigate, useParams } from 'react-router-dom';
import { TreeView } from '../../../../../core/components/lists/TreeView';
import { TreeTogglerTemplateOptions } from 'primereact/tree';
import { AppPermissions } from '../../../../../core/services/AppPermissions';

const PermissionListView: React.FC = observer(() => {
  const vm = Appl.Services.get<PermissionListViewModel>('PermissionListViewModel');
  vm.navigate = useNavigate();
  let { mode, id } = useParams();
  useAsyncEffect(async () => {
    await vm.initAsync(mode as 'user' | 'role', id as unknown as number);
  }, []);

  return <>
    <View id="PermissionListView" pageTitle={vm.pageTitle} authKey={AppPermissions.AuthenticatedUser}
      showMenuBar={false}>
      <ViewHeader title={vm.pageTitle} description={vm.pageDescription} />
      <div className="space-y-6 mt-4">
        <div className='grid grid-cols-1'>
          <TreeView
            id="selectedPermissions"
            value={vm.model?.permissions}
            selectionMode="checkbox"
            selectionKeys={vm.model?.selectedPermissions}
            onSelectionChange={(e) => vm.onSelectedPrivilege(e)}
            filter={true}
            filterPlaceholder={'Search'}
            togglerTemplate={(node: any, options: TreeTogglerTemplateOptions) => (
              <span className="pi pi-angle-right mr-2 hidden" onClick={options.onClick}></span>
            )}
          />
        </div>
        <div className="flex items-center justify-center gap-x-6 my-6">
          <Button title="Save"
            onClick={async () => await vm.saveAsync()} />
          <Button title="Cancel" severity="secondary" onClick={() => vm.navigate(-1)} />
        </div>
      </div>
    </View>
  </>;
});

export default PermissionListView;
