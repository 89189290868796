import { action, makeObservable, observable, runInAction } from 'mobx';
import { Service } from 'typedi';
import { UserModel } from './UserModel';
import { BaseListViewModel } from '../../../../../core/services/BaseViewModel';
import { Appl } from '../../../../../core/Appl';
import { IAccountService } from '../../AccountService';
import { ListItem } from '../../../../../core/services/BaseService';
import { ILovService } from '../../../utility/lov/LovService';
import { AppPermissions } from '../../../../../core/services/AppPermissions';

@Service()
export default class UserViewModel extends BaseListViewModel<UserModel> {
  @observable public model: UserModel = new UserModel();
  private service: IAccountService;
  private lovService: ILovService;
  @observable public roleList: Array<ListItem> = [];
  @observable public genderList: Array<ListItem> = [];
  @observable public id: number = 0;
  @observable public isMemberRole = false;

  constructor() {
    super('User');
    this.service = Appl.Services.get<IAccountService>('IAccountService');
    this.lovService = Appl.Services.get<ILovService>('ILovService');
    makeObservable(this);
  }

  @action
  public async initAsync(id?: number): Promise<void> {
    Appl.Validator.init();
    this.pageTitle = `Add User`;
    this.model = { fname: '', lname: '', usrEmailId: '', phoneNbr: '', personId: undefined, usrGuid: '', usrId: 0, usrRoleId: 1 };
    this.id = id!;
    if (this.id > 0) {
      this.pageTitle = `Edit User #${id}`;
    }
    const result = await this.service?.getByIdAsync(`/identity/user`, this.id);
    runInAction(() => {
      if (result.success) {
        this.model = result.data;
      } else {
        Appl.Error.handle(result.error);
      }
    });
    this.isMemberRole = this.model.usrRoleId === 2;
    const roleListResult = await this.service?.getRolesAsync();
    runInAction(() => {
      if (roleListResult.success) {
        this.roleList = roleListResult.data;
      } else {
        Appl.Error.handle(roleListResult.error);
      }
    });
    const genderListResult = await this.lovService?.getListOfValuesAsync('scers_member.lov_gender');
    runInAction(() => {
      if (genderListResult.success) {
        this.genderList = genderListResult.data;
      } else {
        Appl.Error.handle(genderListResult.error);
      }
    });
  }

  @action
  public async saveAsync(): Promise<void> {
    Appl.Validator.init();
    await Appl.Validator.validateString('First Name', 'fname', this.model.fname, true, 50);
    await Appl.Validator.validateString('Last Name', 'lname', this.model.lname, true, 50);
    await Appl.Validator.validateEmail('Email Address', 'usrEmailId', this.model.usrEmailId, true, 100);
    await Appl.Validator.validateNumber('User Type', 'usrRoleId', this.model.usrRoleId, true);
    await Appl.Validator.validatePhone('Phone', 'phoneNbr', this.model.phoneNbr, false, 20);
    if (this.model.usrRoleId === 1) {//Admin
      if (this.model.permission?.selectedPermissions == undefined || Object.keys(this.model.permission?.selectedPermissions).length == 0) {
        Appl.MessageBox.show('Please select atleast one Permission.', 'Select Permission');
        return;
      }

      if (JSON.stringify(this.model.permission?.selectedPermissions).indexOf(`"${AppPermissions.Dashboard_2_1_VIEW}"`) < 1) {
        Appl.MessageBox.show('Dashboard permission is mandatory and it should be selected.', 'Select Permission');
        return;
      }
      this.model.selectedPermissions = await this.getSelectedPermissions();
    }
    if (this.model.usrRoleId === 2) {//Member
      await Appl.Validator.validateNumber('Gender', 'personGender', this.model.personGender, true);
      await Appl.Validator.validateDate('Date of Birth', 'personDob', this.model.personDob, true, 'mm/dd/yyyy');
      await Appl.Validator.validateNumber('SSN', 'personSsn', this.model.personSsn, true, undefined, undefined, 9, 9);
      this.model.selectedPermissions = undefined;
    }

    if (Appl.Validator.isValid()) {
      const result = await this.service?.createOrUpdateUserAsync(this.model);
      if (result.success) {
        this.model = {};
        if (this.id > 0) {
          Appl.MessageBox.show('User updated successfully.', 'User');
        } else {
          Appl.MessageBox.show('User created successfully.', 'User');
        }
        this.navigate(`/account/user`)
      } else {
        Appl.Error.handle(result.error);
      }
    }
  }

  public async onRoleChange(e: any,): Promise<void> {
    this.model.usrRoleId = e.target.value;
    this.isMemberRole = this.model.usrRoleId === 2;
  }

  @action public async getSelectedPermissions(): Promise<string[]> {
    const selectedItems = [];
    for (const [key] of Object.entries(this.model.permission?.selectedPermissions)) {
      selectedItems.push(key);
    }
    return selectedItems;
  }

  @action public onSelectedPrivilege(value: any): void {
    this.model.permission!.selectedPermissions = value;
  }

}
