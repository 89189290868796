import { action, makeObservable, observable, runInAction } from 'mobx';
import { Service } from 'typedi';
import { RoleModel } from './RoleModel';
import { BaseAddEditViewModel } from '../../../../../core/services/BaseViewModel';
import { Appl } from '../../../../../core/Appl';
import { IAccountService } from '../../AccountService';

@Service()
export default class RoleViewModel extends BaseAddEditViewModel {
  @observable public model: RoleModel = new RoleModel();
  private service: IAccountService;
  @observable public id: number = 0;
  @observable public isMemberRole = false;
  constructor() {
    super('Role');
    this.service = Appl.Services.get<IAccountService>('IAccountService');
    makeObservable(this);
  }

  @action
  public async initAsync(id?: number): Promise<void> {
    this.pageTitle = `Add Role`;
    this.model = { roleId: undefined, roleName: '', roleDesc: '' };
    if (id && id > 0) {
      this.id = id;
      this.pageTitle = `Edit Role #${id}`;
      const result = await this.service?.getByIdAsync(`/identity/role`, this.id);
      runInAction(() => {
        if (result.success) {
          this.model = result.data;
        } else {
          Appl.Error.handle(result.error);
        }
      });
    };
  }

  @action
  public async saveAsync(): Promise<void> {
    Appl.Validator.init();
    await Appl.Validator.validateString('Name', 'roleName', this.model.roleName, true, 50);
    if (Appl.Validator.isValid()) {
      const result = await this.service?.createOrUpdateRoleAsync(this.model);
      if (result.success) {
        this.model = {};
        if (this.id > 0) {
          Appl.MessageBox.show('Role updated successfully.', 'Role');
        } else {
          Appl.MessageBox.show('Role created successfully.', 'Role');
        }
        this.navigate(`/account/role`)
      } else {
        Appl.Error.handle(result.error);
      }
    }
  }
}
