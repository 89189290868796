import React from 'react';
import { observer } from 'mobx-react';
import useAsyncEffect from 'use-async-effect';
import { useLocation, useNavigate } from 'react-router-dom';
import { Appl } from '../../../../core/Appl';
import { Container, View } from '../../../../core/components/Index';
import { AppPermissions } from '../../../../core/services/AppPermissions';

const RedirectView: React.FC = observer(() => {
    console.log("Entering redirect view")
    let navigate = useNavigate();
    const location = useLocation();
    useAsyncEffect(async () => {
        console.log("Entering useAsync")
        let authCode = location.hash;
        let auth = Appl.User.isAuthenticated;
        console.log("Checking for authentication")
        if (auth) {
            navigate('/dashboard');
        } else {
            if (authCode.includes("#code=")) {
                navigate('/redirect');
            }
        }
    }, []);

    return (
        <View id="LoginView" pageTitle="Redirecting..." authKey={AppPermissions.PublicAccess} showMenuBar={false} container={false}>
            <Container>
                <div className="p-10 max-w-lg mx-auto ">
                    <h1 className="text-3xl font-semibold mb-4">Login to continue</h1>
                </div>
            </Container>
        </View>
    )
})
export default RedirectView