import { Service } from 'typedi';
import { action, makeObservable, observable } from 'mobx';
import { ManageReleaseNoteModel } from './ManageReleaseNoteModel';
import { BaseAddEditViewModel } from '../../../../../core/services/BaseViewModel';
import { Appl } from '../../../../../core/Appl';
import { toLocalDate, toServerDate } from '../../../../../core/services/Helper';
import { IReleaseNoteService } from '../ReleaseNoteService';

@Service() // eslint-disable-next-line
export default class ManageReleaseNoteViewModel extends BaseAddEditViewModel {
    private service: IReleaseNoteService;

    @observable public model: ManageReleaseNoteModel = {};
    @observable public id: number = 0;
    constructor() {
        super('Release Note');
        this.service = Appl.Services.get<IReleaseNoteService>('IReleaseNoteService');
        makeObservable(this);
    }

    ///https://markdownlivepreview.com/
    @action
    public async initAsync(id?: number): Promise<void> {
        this.model = {
            buildId: 0, buildNbr: '', buildDate: undefined,
            buildNotes:
                `## New Features
* Feature 1
* Feature 2

## Bug Fixes
*  Bug 1
*  Bug 2

## Other Changes
* Other Changes 1
* Other Changes 1`
        };
        if (id && id > 0) {
            this.id = id;
            this.pageTitle = `Release Note #${id}`;
            const result = await this.service?.getByIdAsync(`/utility/releasenotes`, this.id);
            if (result.success) {
                this.model = result.data;
                this.model.buildDate = toLocalDate(this.model.buildDate);
            } else {
                Appl.Error.handle(result.error);
            }
        }
    }

    @action
    public async saveAsync(): Promise<void> {
        Appl.Validator.init();
        await Appl.Validator.validateString('Release#', 'buildNbr', this.model.buildNbr, true, 10);
        await Appl.Validator.validateDate('Release Date', 'buildDate', this.model.buildDate, true, "mm/dd/yyyy");
        await Appl.Validator.validateString('Release Notes', 'buildNotes', this.model.buildNotes, true, 4000);
        if (Appl.Validator.isValid()) {
            this.model.buildDate = toServerDate(this.model.buildDate);
            const result = await this.service?.createOrUpdateAsync(this.model);
            if (result.success) {
                this.model = {};
                if (this.id > 0) {
                    Appl.MessageBox.show('Release Note updated successfully.', 'Release Note');
                } else {
                    Appl.MessageBox.show('Release Note created successfully.', 'Release Note');
                }
                this.navigate(`/utility/releases`)
            } else {
                Appl.Error.handle(result.error);
            }
        }
    }
}
