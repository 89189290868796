import AccountModule from '../modules/admin/account/AccountModule';
import ConfigData from './AppConfig.json';
import SraModule from '../modules/admin/sra/SraModule';
import RapModule from '../modules/admin/rap/RapModule';
import ChangeOfAddressModule from '../modules/admin/members/changeOfAddress/ChangeOfAddressModule';
import DeathModule from '../modules/admin/members/deaths/DeathModule';
import LovModule from '../modules/admin/utility/lov/LovModule';
import SecureFileUploadModule from '../modules/admin/members/secureFileUpload/SecureFileUploadModule';
import NotificationModule from '../modules/admin/utility/notification/NotificationModule';
import { makeAutoObservable, observable } from 'mobx';
import ReleaseNoteModule from '../modules/admin/utility/releaseNote/ReleaseNoteModule';
import CommonModule from '../modules/common/CommonModule';

export interface ISetting {
  AppVersion: string;
  EnvironmentName: string;
  UseModelDialogForEdits: boolean;
  GridPageSize: number;
  AllowedFileExtensions: string;
  AllowedFileSizeBytes: number;
  LoginSuccessNavigateUrl?: string;
  IdleTimeoutSeconds: number;
  IdlePromptTimeoutSeconds: number;
  IdleRedirectUrl: string;
  IdleTimeourtExcludeRoutes: Array<string>;
  EnableAllFeatures: boolean;
  Services: {
    RetirementServiceUrl?: string;
    MemberServiceUrl?: string;
    UtilityServiceUrl?: string;
    IdentityServiceUrl?: string;
    RapServiceUrl?: string;
  }
  AuthProviders?: [
    {
      Provider: string;
      ClientId?: string;
      AuthorityUrl?: string;
      UserInfoUrl?: string;
      LoginRedirectUrl?: string;
      LogoutUrl?: string;
      Scopes: Array<string>;
      UserAttributes: {
        DisplayName?: string;
        UserId?: string;
        Email?: string;
      }
    }
  ];
}

export class AppConfig {
  private static _instance: AppConfig;
  public static get instance() {
    return this._instance ?? (this._instance = new AppConfig());
  }

  @observable public Setting?: ISetting;

  constructor() {
    let envName = this.getEnvironment();
    this.Setting = ConfigData.Setting.find(o => o.EnvironmentName === envName) as ISetting;
    this.Setting.AppVersion = "1.0.0";
    this.Setting.EnableAllFeatures = (envName === "qual" || envName === "qual1" || envName === "local");
    makeAutoObservable(this);
  }

  private getEnvironment(): string {
    let env = 'local';
    const location = window.location.host.toLowerCase();
    if (location.includes('localhost')) {
      env = 'local';
    } else if (location.includes('red-dune')) {
      env = 'qual';
    } else if (location.includes('qual-frontend')) {
      env = 'qual1';
    }
    else if (location.includes('test.scers')) {
      env = 'test';
    } else if (location.includes('uat.scers')) {
      env = 'uat';
    } else if (location.includes('uat-frontend')) {
      env = 'uat1';
    }
    else {
      env = 'prod';
    }
    // env = 'qual';
    return env;
  }

  public async getReleaseInfo(): Promise<string> {
    let releaseInfo = `Build#: 1.0.0 - Date: 00/00/0000`
    const versionInfoUrl = `${this.Setting?.Services.UtilityServiceUrl}/utility/ReleaseNotes/latest-release`
    const request = new Request(versionInfoUrl, {
      method: "GET",
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      }
    });
    let resposne = await fetch(request);
    if (resposne.ok) {
      let data = await resposne.json();
      releaseInfo = `Build: ${data?.buildNbr} - Date: ${data?.buildDate}`
      if (data.envName === "prod") {
        return releaseInfo;
      } else {
        return `${releaseInfo} (${data.envName.toUpperCase()})`;
      }
    }
    return releaseInfo;
  }

  public static init(): void {
    LovModule.init();
    NotificationModule.init();
    AccountModule.init();
    SraModule.init();
    RapModule.init();
    SecureFileUploadModule.init();
    ChangeOfAddressModule.init();
    DeathModule.init();
    ReleaseNoteModule.init();
    CommonModule.init();
  }
}