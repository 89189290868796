import { lazyComponent } from '../core/components/LazyComponent';
import { Outlet, RouteObject, useRoutes } from 'react-router-dom';
import App from '../App';
import { NotAuthorizedView } from '../core/pages/NotAuthorizedView';
import { HelpView } from '../core/pages/HelpView';
import { PageNotImplementedView } from '../core/pages/PageNotImplementedView';
import { PageNotFoundView } from '../core/pages/PageNotFoundView';
import { Appl } from '../core/Appl';
import LoginView from '../modules/admin/account/login/LoginView';
import ComponentsView from '../ui/components';
import { SubmissionStatusView } from '../modules/admin/sra/retiree/memberCreate/12_1_SubmissionStatusView';
import RedirectView from '../modules/admin/account/login/RedirectView';
import LogoutView from '../modules/admin/account/login/LogoutView';
import { SubmissionListView } from '../modules/admin/sra/submission/list/SubmissionListView';
import ChangeOfAddressView from '../modules/admin/members/changeOfAddress/addEdit/ChangeOfAddressView';
import DeathReportView from '../modules/admin/members/deaths/deathReport/addEdit/DeathReportView';
import { DeathReportListView } from '../modules/admin/members/deaths/deathReport/list/DeathReportListView';
import { ChangeOfAddressListView } from '../modules/admin/members/changeOfAddress/list/ChangeOfAddressListView';
import PrintApplication from '../modules/admin/sra/retiree/memberCreate/10_1_PrintApplication';
import { LovListView } from '../modules/admin/utility/lov/list/LovListView';
import LovView from '../modules/admin/utility/lov/addEdit/LovView';
import { MemberLookupView } from '../modules/admin/members/secureFileUpload/invite/docUpload/MemberLookupView';
import { UploadRequestListView } from '../modules/admin/members/secureFileUpload/list/UploadRequestListView';
import { NotificationListView } from '../modules/admin/utility/notification/list/NotificationListView';
import { ReleaseNoteListView } from '../modules/admin/utility/releaseNote/list/ReleaseNoteListView';
import ReleaseNotesView from '../modules/admin/utility/releaseNote/note/ReleaseNotesView';
import ManageReleaseNoteView from '../modules/admin/utility/releaseNote/addEdit/ManageReleaseNoteView';
import { RapCalculatorView } from '../modules/admin/rap/calculator/RapCalculatorView';
import { DashboardView } from '../modules/common/dashboard/DashboardView';
import UserListView from '../modules/admin/account/user/list/UserListView';
import UserView from '../modules/admin/account/user/addEdit/UserView';
import RoleListView from '../modules/admin/account/role/list/RoleListView';
import RoleView from '../modules/admin/account/role/addEdit/RoleView';
import PermissionListView from '../modules/admin/account/role/permission/PermissionListView';

export function RouteConfig() {

  const SecuredRoutes = () => {
    return (
      Appl.User.isAuthenticated ? <Outlet /> : <LoginView />
    );
  };

  let accountPublicRoutes: Array<RouteObject> = [
    { path: '', element: lazyComponent('modules/public/home/HomeView') },
    { path: '/login', element: <LoginView /> },
    { path: '/logout', element: <LogoutView /> },
    { path: '/redirect', element: <RedirectView /> },//element: lazyRoute("modules/admin/identity/auth/LoginView")
    // { path: '/account/role', element: <RoleListView /> },
    // { path: '/account/role/:id', element: <RoleView /> },
    { path: '/account/:mode/:id/permission', element: <PermissionListView /> },
    { path: '/account/user', element: <UserListView /> },
    { path: '/account/user/:id', element: <UserView /> },
    // { path: '/logout', element: lazyComponent('modules/admin/identity/auth/LogoutView') },
    { path: 'noauthorization', element: <NotAuthorizedView /> },
    { path: 'help/:id', element: <HelpView /> },
    { path: 'unimplemented', element: <PageNotImplementedView /> },
    { path: '/components', element: <ComponentsView /> },
    { path: '/utility/release-notes', element: <ReleaseNotesView /> },
    { path: '*', element: <PageNotFoundView /> },
  ];

  let retirementSecuredRoutes: Array<RouteObject> = [
    // { path: 'sra', element: <DashboardView /> },
    // { path: 'sra/planning-checklist', element: <PlanningCheckListView /> },
    // { path: 'sra/retirement-considerations', element: <RetirementConsiderationsView /> },
    { path: 'sra/retiree', element: <SubmissionListView /> },
    { path: 'sra/retiree/addEdit/:submissionId', element: lazyComponent("modules/admin/sra/retiree/addEdit/SubmissionView") },
    { path: 'sra/retiree/application/:memberId/*', element: lazyComponent("modules/admin/sra/retiree/memberCreate/ApplicationLayout") },
    { path: 'sra/retiree/application/:memberId/print/:submissionId', element: <PrintApplication /> },
    { path: 'sra/retiree/afr-status/:memberId', element: <SubmissionStatusView /> },
  ];

  let changeOfAddressRoutes: Array<RouteObject> = [
    { path: 'members/change-of-address', element: <ChangeOfAddressListView /> },
    { path: 'members/change-of-address/:id', element: <ChangeOfAddressView /> },
  ];

  let deathsRoutes: Array<RouteObject> = [
    { path: 'members/death-report', element: <DeathReportListView /> },
    { path: 'members/death-report/:id', element: <DeathReportView /> },
  ];

  let notificationRoutes: Array<RouteObject> = [
    { path: 'event-notification', element: <NotificationListView /> },
  ];

  let lovRoutes: Array<RouteObject> = [
    { path: 'lov/:type?', element: <LovListView /> },
    { path: 'lov/:type/:id', element: <LovView /> },
  ];

  let rapSecuredRoutes: Array<RouteObject> = [
    { path: 'rap/calculator', element: <RapCalculatorView /> },
  ];

  let memberFilesSecuredRoutes: Array<RouteObject> = [
    { path: 'members/invite/doc-upload', element: <MemberLookupView /> },
    { path: 'members/upload-requests', element: <UploadRequestListView /> },
  ];

  let releaseNotesSecuredRoutes: Array<RouteObject> = [
    { path: 'utility/releases', element: <ReleaseNoteListView /> },
    { path: 'utility/releases/addEdit/:id', element: <ManageReleaseNoteView /> },
  ];

  let securedRoutes = new Array<RouteObject>;
  securedRoutes.push({ path: 'dashboard', element: <DashboardView /> });
  if (Appl.Setting.EnableAllFeatures) {
    securedRoutes.push(...retirementSecuredRoutes);
    securedRoutes.push(...changeOfAddressRoutes);
    securedRoutes.push(...rapSecuredRoutes);
    securedRoutes.push(...notificationRoutes);
    securedRoutes.push(...lovRoutes);
    securedRoutes.push(...releaseNotesSecuredRoutes);
  }
  securedRoutes.push(...deathsRoutes);
  securedRoutes.push(...memberFilesSecuredRoutes);

  let publicRoutes = new Array<RouteObject>;
  publicRoutes.push(...accountPublicRoutes);

  const element = useRoutes([
    { path: '', element: <App />, children: [{ element: <SecuredRoutes />, children: securedRoutes }, { children: publicRoutes }] },
  ]);
  return element;
}
