import { Service } from 'typedi';
import { BaseListViewModel } from '../../../../../core/services/BaseViewModel';
import { Appl } from '../../../../../core/Appl';
import { ReleaseNoteListModel } from './ReleaseNoteListModel';
import { makeObservable } from 'mobx';
import { IReleaseNoteService } from '../ReleaseNoteService';

@Service() // eslint-disable-next-line
export default class ReleaseNoteListViewModel extends BaseListViewModel<ReleaseNoteListModel> {
  private service: IReleaseNoteService;
  constructor() {
    super('Release Note List', 'buildId', false);
    this.service = Appl.Services.get<IReleaseNoteService>('IReleaseNoteService');
    makeObservable(this);
  }

  public async getPagedAsync(): Promise<void> {
    const response = await this.service.getPagedAsync(`/utility/ReleaseNotes/search`, this.searchRequest);
    await this.handleGetAllServerResponse(response);
  }
}
