import { observer } from 'mobx-react';
import { View, ViewHeader } from '../../../../../core/components/Index'
import useAsyncEffect from 'use-async-effect';
import { useNavigate } from 'react-router-dom';
import { Appl } from '../../../../../core/Appl';
import ReleaseNotesViewModel from './ReleaseNotesViewModel';
import Markdown from 'react-markdown';
import 'github-markdown-css/github-markdown.css';
import { formatDate } from '../../../../../core/services/Helper';
import { AppPermissions } from '../../../../../core/services/AppPermissions';

const ReleaseNotesView = observer(() => {
  const vm = Appl.Services.get<ReleaseNotesViewModel>('ReleaseNotesViewModel');
  vm.navigate = useNavigate();
  useAsyncEffect(async () => {
    await vm.initAsync();
  }, []);

  return (
    <>
      <View
        id="ReleaseNotesView"
        pageTitle={vm.pageTitle}
        authKey={AppPermissions.PublicAccess}
        showMenuBar={false}
      >
        <ViewHeader title={vm.pageTitle} description={vm.pageDescription} helpPageId="" backUrl="" />
        {vm.model && vm.model.map((releaseNote, index) => {
          return (
            <div key={`ReleaseNote${index}`} className="space-y-6 mt-2">
              <h1 className="text-22px font-semibold mb-0 text-black">Release {releaseNote.buildNbr}</h1>
              <label>{formatDate(releaseNote.buildDate)}</label>
              <div className="markdown-body p-4 mb-2">
                <Markdown>{releaseNote.buildNotes}</Markdown>
              </div>
            </div>
          );
        })}
        {/* <div className="space-y-6 mt-4">
          <h1 className="text-22px font-semibold mb-0 text-black">Release 1.0.0</h1>
          <label>December 21, 2024</label>
          <div>
            <Markdown># Hello, *world*!</Markdown>
            <p className='text-20px font-semibold mt-4 text-black'>New Features</p>
            <ul className='list-disc ml-4 font-medium'>
              <li>Feature 1</li>
              <li>Feature 2</li>
            </ul>
          </div>
          <div>
            <p className='text-20px font-semibold mb-4 text-black'>Bug Fixes</p>
            <ul className='list-disc ml-4 font-medium'>
              <li>Bug 1</li>
              <li>Bug 2</li>
            </ul>
          </div>
          <hr />
        </div>
        <div className="space-y-6 mt-4">
          <h1 className="text-22px font-semibold mb-0 text-black">Release 0.0.9</h1>
          <label>November 01, 2024</label>
          <div>
            <p className='ext-20px font-semibold mt-4 text-black'>New Features</p>
            <ul className='list-disc ml-4 font-medium'>
              <li>Feature 1</li>
              <li>Feature 2</li>
            </ul>
          </div>
          <div>
            <p className='ext-20px font-semibold mb-4 text-black'>Bug Fixes</p>
            <ul className='list-disc ml-4 font-medium'>
              <li>Bug 1</li>
              <li>Bug 2</li>
            </ul>
          </div>
          <hr />
        </div> */}
      </View >
    </>
  );
});

export default ReleaseNotesView;
