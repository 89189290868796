import React from 'react';
import { observer } from 'mobx-react';
import { DataGrid, Icon, SearchToolbar, View, ViewHeader } from '../../../../../core/components/Index';
import { Link, useNavigate } from 'react-router-dom';
import useAsyncEffect from 'use-async-effect';
import { Column } from 'primereact/column';
import { ReleaseNoteListModel } from './ReleaseNoteListModel';
import { Appl } from '../../../../../core/Appl';
import ReleaseNoteListViewModel from './ReleaseNoteListViewModel';
import { formatDate } from '../../../../../core/services/Helper';
import { AppPermissions } from '../../../../../core/services/AppPermissions';

export const ReleaseNoteListView: React.FC = observer(() => {
  const vm = Appl.Services.get<ReleaseNoteListViewModel>('ReleaseNoteListViewModel');
  vm.navigate = useNavigate();
  useAsyncEffect(async () => {
    await vm.getPagedAsync();
  }, []);


  const actionTemplate = (row: ReleaseNoteListModel) => {
    let editLink = `/utility/releases/addEdit/${row.buildId}`;
    return (
      <div className="flex">
        <Link to={editLink} title="Edit">
          <Icon name="file-pen-line" size={24} className="link me-2" title="Edit" />
        </Link>
        {/* <span className="link"
          onClick={async () => await vm.showPrintDialog(row.mbrChangeOfAddressId!)}
          title='View/Print'>
          <Icon name="printer" size={24} color="black" className="me-2" title="Print" />
        </span> */}
      </div>
    );
  };
  const buildDateTemplate = (row: ReleaseNoteListModel) => {
    return formatDate(row.buildDate);
  };
  return (
    <>
      <View id="ReleaseNoteListView" pageTitle={vm.pageTitle}
        authKey={AppPermissions.ReleaseNotes_19_18_VIEW}
        showMenuBar={false}>
        <ViewHeader title={vm.pageTitle} description={vm.pageDescription}>
          <SearchToolbar
            filterPlaceholder="RELEASE#"
            filter={vm.searchRequest?.filter}
            onFilterChange={(e: any) => vm.onInputChange(e, 'filter', vm.searchRequest)}
            onRefresh={async () => await vm.getPagedAsync()}
            addNewVisible={true}
            onAddNew={() => vm.navigate('/utility/releases/addEdit/0')}
            refreshVisible={false}
            showButton={false}
            // run search on input change
            searchOnInputChange={true}
          />
        </ViewHeader>
        <DataGrid
          pageSize={vm.pageSize}
          dataSource={vm.items}
          totalRowsCount={vm.totalRowsCount}
          searchRequest={vm.searchRequest}
          onRefresh={() => vm.getPagedAsync()}>
          <Column field="buildId" header="ID" sortable={true} />
          <Column field="buildNbr" header="RELEASE #" sortable={true} />
          <Column field="buildDate" header="RELEASE DATE" sortable={true} body={buildDateTemplate} />
          <Column body={actionTemplate} header="Manage" />
        </DataGrid>
      </View>
    </>
  );
});
