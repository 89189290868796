import { Permission } from "./IUserContext";

export interface NavItem {
    id: string;
    label: string;
    path?: string;
    icon?: string;
    children?: NavItem[];
    alias?: string[];
}

export const getMenuItems = (permissions: Permission[]) => {
    let navigationItems: { section: string | undefined; items: NavItem[] }[] = [];
    const distGroups = permissions.filter((obj, index, self) =>
        index === self.findIndex((t) => t.groupLabel === obj.groupLabel && t.type === "MODULE")
    );
    for (const distGroup of distGroups) {
        let groupPermissions = permissions.filter(o => o.groupLabel === distGroup.groupLabel! && o.type === "MODULE");
        let items = getNavModuleItems(groupPermissions)
        let navSection = [{ section: distGroup.groupLabel, items: items }]
        navigationItems.push(...navSection!)
    }

    return navigationItems;
}
export const getNavItems = (permissions: Permission[]) => {
    let navigationItems: { section: string; items: NavItem[] }[] = [];
    if (permissions) {
        for (const permission of permissions) {
            let node = permission!
            if (node.type! === 'ACTION') {
                continue;
            }
            if (node.type! === 'MODULE') {
                let navItem: NavItem =
                {
                    id: node.id.toString(),
                    label: node.label!,
                    icon: node.icon,
                    children: [...getNavPageItems(node.children!)!]
                }
                navigationItems.push({
                    section: node.groupLabel!,
                    items: [navItem]
                })
            }
        }
        return navigationItems;
    }
}

export const getNavModuleItems = (permissions: Permission[]) => {
    let navItems: NavItem[] = [];
    for (const permission of permissions.filter(o => o.type === "MODULE")) {
        navItems.push({
            id: permission.id.toString(),
            label: permission.label!,
            icon: permission.icon,
            children: [...getNavPageItems(permission.children!)!]
        });
    }
    return navItems;
}

export const getNavPageItems = (permissions: Permission[]) => {
    let navItems: NavItem[] = [];
    for (const permission of permissions.filter(o => o.type === "PAGE")) {
        navItems.push({
            id: permission.id.toString(),
            label: permission.label!,
            icon: permission.icon,
            path: permission.url,
            alias: [permission.url!],
            children: []
        });
    }
    return navItems;
}
