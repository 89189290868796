import { action, makeObservable, observable } from 'mobx';
import { Service } from 'typedi';
import { RoleListModel } from './RoleListModel';
import { BaseListViewModel } from '../../../../../core/services/BaseViewModel';
import { IAccountService } from '../../AccountService';
import { Appl } from '../../../../../core/Appl';

@Service()
export default class RoleListViewModel extends BaseListViewModel<RoleListModel> {
  @observable public model: RoleListModel = new RoleListModel();
  private service: IAccountService;

  constructor() {
    super('Role List', 'roleId', false);
    this.service = Appl.Services.get<IAccountService>('IAccountService');
    makeObservable(this);
  }

  public async getPagedAsync(): Promise<void> {
    const response = await this.service.getPagedAsync(`/identity/role/search`, this.searchRequest);
    await this.handleGetAllServerResponse(response);
  }

  @action
  public async deleteAsync(id?: number): Promise<void> {
    const result = await this.service?.deleteAsync('/identity/role', id!);
    if (result.success) {
      await this.getPagedAsync()
    } else {
      Appl.Error.handle(result.error);
    }
  }

}
