import { action, makeObservable, observable } from 'mobx';
import { Service } from 'typedi';
import { BaseViewModel } from '../../../../core/services/BaseViewModel';
import { Appl } from '../../../../core/Appl';
// import { ILovService } from '../../utility/lov/LovService';
import { ListItem } from '../../../../core/services/BaseService';
// import { ISecureFileUploadService } from '../../members/secureFileUpload/SecureFileUploadService';
import { RaPMbrLookupModel, RapResultsModel } from './RapCalculatiorModel'
import { IRapService } from '../RapService';
import { CheckboxChangeEvent } from 'primereact/checkbox';



@Service() // eslint-disable-next-line
export default class RapCalculatorViewModel extends BaseViewModel {
  private service: IRapService;
  // private lovService: ILovService;
  @observable public model: RaPMbrLookupModel;
  @observable public documentTypeListItems?: Array<ListItem> = [];
  @observable public otherDocTextBoxVisible = false;
  @observable public unmodifiedContinuanceSelected = false;
  @observable public eligibilityOverrideSelected = false;
  @observable public mortalityOverrideSelected = false;
  @observable public option2or3Selected = false;
  @observable public enableCalculateButton = true;
  @observable public rapResults = '';

  constructor() {
    super();
    this.service = Appl.Services.get<IRapService>('IRapService');
    //   this.lovService = Appl.Services.get<ILovService>('ILovService');
    this.model = {};
    makeObservable(this);
  }


  @action public async initAsync(): Promise<void> {
    this.pageTitle = 'RAP Calculator';
    if (Appl.User.isAuthenticated) {
      this.pageTitle = 'RAP Calculator';
    }
  }
  //   @action public async downloadDocumentAsync(docDtlId: number, fileName: string): Promise<void> {
  //     const result = await this.service?.downloadAsync(`/members/SecureFileUpload/download/${docDtlId}`);
  //     if (result.success) {
  //       let fileExt = fileName.split('.')[1];
  //       fileName = `SFU-${docDtlId}-${new Date().getFullYear()}${(new Date().getMonth() + 1)}${new Date().getDate()}.${fileExt}}`;
  //       Helper.downloadFile(result.data, fileName, '');
  //     } else {
  //       Appl.Error.handle(result.error);
  //     }
  //   }
  @action
  public async formatDateToMMDDYYYY(date: Date): Promise<string> {
    const d = new Date(date);
    const month = (d.getMonth() + 1).toString().padStart(2, "0"); // Month (1-12)
    const day = d.getDate().toString().padStart(2, "0"); // Day (1-31)
    const year = d.getFullYear(); // Year (e.g., 2025)
    return `${month}-${day}-${year}`;
  }

  @action
  public async calculateRAPAsync(): Promise<void> {
    // Initialize the Validator
    Appl.Validator.init();

    // Validate inputs
    // await Appl.Validator.validateString('First Name', 'mbrFirstName', this.model.mbrFirstName, true, 50);
    // await Appl.Validator.validateString('Last Name', 'mbrLastName', this.model.mbrLastName, true, 50);
    // await Appl.Validator.validateString('Employee ID', 'mbrEmployeeID', this.model.mbrEmployeeID, true, 50);
    // await Appl.Validator.validateNumber('SSN', 'mbrSsn', this.model.mbrSSN, false, 10000);
    await Appl.Validator.validateString('Member Name', 'mbrName', this.model.mbrName, false, 50);
    await Appl.Validator.validateString('Benefeciary Name', 'bnfName', this.model.bnfName, false, 50);
    await Appl.Validator.validateDate('Benefeciary DOB', 'bnfDOB', this.model.bnfDOB, true, "mm/dd/yyyy");
    if (new Date(this.model.bnfDOB!) > new Date()) {
      await Appl.Validator.setError("bnfDOB", "Date of Birth should not be future date.");
    }
    await Appl.Validator.validateDate('Member DOB', 'bnfDOB', this.model.mbrDOB, true, "mm/dd/yyyy");
    if (new Date(this.model.mbrDOB!) > new Date()) {
      await Appl.Validator.setError("mbrDOB", "Date of Birth should not be future date.");
    }
    await Appl.Validator.validateDate('Retirement Date', 'retirementDate', this.model.retirementDate, true, "mm/dd/yyyy");
    if (new Date(this.model.retirementDate!) <= new Date()) {
      await Appl.Validator.setError("retirementDate", "Retirement Date should be future date.");
    }
    // await Appl.Validator.validateString('Relationship', 'bnfrelation', this.model.bnfRelation, true, 3);
    // Format the fields
    // this.model.retirementDate = new Date(this.model.retirementDate).toISOString() as undefined;
    // this.model.bnfDOB = new Date(this.model.bnfDOB).toISOString() as undefined;
    // this.model.mbrDOB = new Date(this.model.mbrDOB).toISOString() as undefined;
    if (Appl.Validator.isValid()) {
      // Render HTML table
      // this.enableCalculateButton = false
      const jsonData = {
        "TypeOfRetirement": "SR",
        "DateOfRetirement": "2002-10-16T00:00:00",
        "Membership": "General",
        "Tier": "Three",
        "MoneyPurchase": "No",
        "MemberInfo": {
          "Name": "George Jefferson",
          "DOB": "1941-07-27T00:00:00",
          "Age": 61.22,
          "Age1by4": 61,
          "AgeTrunc": 61
        },
        "BeneficiaryInfo": {
          "Name": "Deborah Jefferson",
          "DOB": "1949-05-23T00:00:00",
          "Age": 53.4,
          "Age1by4": 53.25,
          "AgeTrunc": 53
        },
        "RelationShip": "Spouse",
        "IntegratedService": {
          "Years": 29,
          "Months": 8,
          "Days": 16.4999
        },
        "ISSick": {
          "Years": 1,
          "Months": 0,
          "Days": 11.4124
        },
        "NonIntegratedService": {
          "Years": 0,
          "Months": 0,
          "Days": 0
        },
        "NonISSick": {
          "Years": 0,
          "Months": 0,
          "Days": 0
        },
        "EEContrBasic": 58106.4,
        "EEContrCol": 6629.87,
        "FinalComp": 4803.45,
        "InterestRate": 0.08,
        "COLA": 0.02,
        "FeqToPay": 0.5,
        "RoundOption1Fac": 0.002739726,
        "ContinueOption4": 0.5,
        "MemberMortalityTable": 1,
        "MemberSetback": -3,
        "BeneficiaryMortalityTable": 1,
        "BeneficiarySetback": -3
      }
      const response = await this.service?.getRAPEstimateAsync(jsonData)
      if (response.success) {
        this.rapResults = response.data;
        this.rapResults = `<div>{response}</div>`;
      } else {
        Appl.Error.handle(response.error);
      }
      // this.rapResults = `
      //                     <div>
      //                         <table border="1">
      //                             <thead>
      //                                 <tr>
      //                                     <th>Option</th>
      //                                     <th>Current Service Annuity</th>
      //                                     <th>Current Service Pension</th>
      //                                     <th>Total Pension</th>
      //                                     <th>Continuance to Spouse</th>
      //                                 </tr>
      //                             </thead>
      //                             <tbody>
      //                                 <tr>
      //                                     <td>Unmodified</td>
      //                                     <td>475.81</td>
      //                                     <td>3290.36</td>
      //                                     <td>3766.17</td>
      //                                     <td>2259.7</td>
      //                                 </tr>
      //                                 <tr>
      //                                     <td>Option 1</td>
      //                                     <td>459.54</td>
      //                                     <td>3290.30</td>
      //                                     <td>3749.9</td>
      //                                     <td></td>
      //                                 </tr>
      //                                 <tr>
      //                                     <td>Option 2</td>
      //                                     <td>407.65</td>
      //                                     <td>2518.99</td>
      //                                     <td>3226.64</td>
      //                                     <td>3226.64</td>
      //                                 </tr>
      //                                 <tr>
      //                                     <td>Option 3</td>
      //                                     <td>439.1</td>
      //                                     <td>3036.46</td>
      //                                     <td>3475.56</td>
      //                                     <td>1737.78</td>
      //                                 </tr>
      //                                 <tr>
      //                                     <td>Basic</td>
      //                                     <td>58106.4</td>
      //                                     <td>401818.28</td>
      //                                     <td>459924.68</td>
      //                                     <td></td>
      //                                 </tr>
      //                                 <tr>
      //                                     <td>COL</td>
      //                                     <td>6629.87</td>
      //                                     <td>83067</td>
      //                                     <td>89697.04</td>
      //                                     <td></td>
      //                                 </tr>
      //                             </tbody>
      //                         </table>
      //                     </div>
      //                 `;
    }
    else {
      this.rapResults = `<div><h2> Invalid Input. Please try again </h2> </div>`
    }


    // Uncomment and implement the following line if required in the future
    // const result = await this.service?.downloadAsync('/members/SecureFileUpload/download/');

    // Optional email validation (currently commented out)
    // await Appl.Validator.validateEmail('Email', 'memberEmail', this.model.memberEmail, false, 100);
  };


  @action onMemberInfoChange = (e: any, id: string) => {
    (this.model as any)[id] = e.target?.value;
    this.verifyMandatoryFieldsFilled();
  }

  @action public verifyMandatoryFieldsFilled(): void {
    if (this.model.retirementDate
      && this.model.mbrDOB && this.model.bnfDOB
      && this.model.finalComp && this.model.integratedServiceCredits && this.model.integratedSickLeave
      && this.model.nonintegratedServiceCredits && this.model.nonintegratedSickLeave) {
      this.enableCalculateButton = true;
    } else {
      this.enableCalculateButton = false;
    }
  };

  @action unmodifiedContinuanceChange = (e: CheckboxChangeEvent) => {
    this.unmodifiedContinuanceSelected = e.checked!;
    if (this.unmodifiedContinuanceSelected) {
      this.model.unmodifiedContinuance = true
    }
    else {
      this.model.unmodifiedContinuance = false
    }
  };
  // this.verifyMandatoryFieldsFilled();

  @action option2or3Change = (e: CheckboxChangeEvent) => {
    this.option2or3Selected = e.checked!;
    // if (this.option2or3Change) {
    //   this.model.OptionTwoThree = true
    // }
    // else {
    //   this.model.OptionTwoThree = false
    // }
  };

  @action eligibilityOverrideChange = (e: CheckboxChangeEvent) => {
    this.eligibilityOverrideSelected = e.checked!;
    if (this.eligibilityOverrideSelected) {
      this.model.eligibilityOverride = true
    }
    else {
      this.model.eligibilityOverride = false
    }
  };


  @action mortalityOverrideOverrideChange = (e: CheckboxChangeEvent) => {
    this.mortalityOverrideSelected = e.checked!;
    if (this.mortalityOverrideSelected) {
      this.model.mortalityOverride = true
    }
    else {
      this.model.mortalityOverride = false
    }
  };
}