import React from 'react';
import {Tree, TreeProps, TreeTogglerTemplateOptions, TreeEventNodeEvent} from 'primereact/tree';
import {observer} from 'mobx-react';

export const TreeView: React.FC<
  TreeProps & {
    filter?: boolean;
    filterPlaceholder?: string;
    className?: string;
    onSelectionChange: (value: any) => void;
    disable?: boolean;
    id?: string;
    nodeTemplate?: any;
    contentClassName?: string;
    onExpand?: (node: TreeEventNodeEvent) => void;
    onCollapse?: (node: TreeEventNodeEvent) => void;
    metaKeySelection?: boolean;
    togglerTemplate?: (node: TreeProps, options: TreeTogglerTemplateOptions) => void;
  }
> = observer((props) => {
  return (
    <>
      <Tree
        id={props.id}
        value={props.value}
        selectionMode={props.selectionMode ? props.selectionMode : 'checkbox'}
        selectionKeys={props.selectionKeys!}
        onSelectionChange={(e) => {
          props.onSelectionChange(e.value);
        }}
        filter={props.filter ? props.filter : false}
        filterPlaceholder={props.filterPlaceholder ? props.filterPlaceholder : ''}
        expandedKeys={props.expandedKeys}
        className={`${props.className} custom-tree-classes`}
        contentClassName={props.contentClassName}
        nodeTemplate={props.nodeTemplate}
        metaKeySelection={props.metaKeySelection}
        filterMode={props.filterMode}
        style={{border: 0}}
      />
    </>
  );
});
