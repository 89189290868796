import { RapService } from './RapService';
import { Appl } from '../../../core/Appl';
import { NavItem } from '../../../core/services/Models';
import RapCalculatorViewModel from './calculator/RapCalculatorViewModel';

export default class RapModule {

  public static MenuItems: Array<NavItem> = [
    // { id: 'rap', label: 'Dashboard', path: '/rap' },
    // { id: 'unimplemented', label: 'RAP Browser', path: '/unimplemented' },
    // { id: 'unimplemented', label: 'Estimate RAP', path: '/unimplemented' },
    // { id: 'unimplemented', label: 'Maintenance Tables', path: '/unimplemented' },
    // { id: 'rapCalculator', label: 'RAP Calculator', path: '/rap-calculator' }

  ]

  public static init(): void {
    Appl.Services.add('IRapService', RapService);
    Appl.Services.add('RapCalculatorViewModel', RapCalculatorViewModel);
  }
}
