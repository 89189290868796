import React from 'react';
import useAsyncEffect from 'use-async-effect';
import { observer } from 'mobx-react';
import UserViewModel from './UserViewModel';
import { Appl } from '../../../../../core/Appl';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../../../../core/components/actions/Button';
import { Input, InputCalendar, InputDropdown, InputType, View, ViewHeader } from '../../../../../core/components/Index';
import { TreeView } from '../../../../../core/components/lists/TreeView';
import { TreeTogglerTemplateOptions } from 'primereact/tree';
import { AppPermissions } from '../../../../../core/services/AppPermissions';

const UserView: React.FC = observer(() => {
  const vm = Appl.Services.get<UserViewModel>('UserViewModel');
  vm.navigate = useNavigate();
  let { id } = useParams();
  useAsyncEffect(async () => {
    await vm.initAsync(id as unknown as number);
  }, []);

  return (
    <>
      <View
        id="UserView"
        pageTitle={vm.pageTitle}
        authKey={AppPermissions.Users_17_16_VIEW}
        showMenuBar={false}
      >
        <ViewHeader title={vm.pageTitle} description={vm.pageDescription} helpPageId="" backUrl="" />
        <div className="space-y-6 mt-4">
          <div className='grid grid-cols-4 gap-4'>
            <div>
              <Input
                id="fname"
                value={vm.model.fname}
                inputType={InputType.Text}
                onChange={(e: any) => {
                  vm.onInputChange(e, 'fname', vm.model);
                }}
                label="First Name"
                required={true}
                maxLength={50}
              />
            </div>
            <div>
              <Input
                id="lname"
                value={vm.model.lname}
                inputType={InputType.Text}
                onChange={(e: any) => {
                  vm.onInputChange(e, 'lname', vm.model);
                }}
                label="Last Name"
                required={true}
                maxLength={50}
              />
            </div>
            <div>
              <Input
                id="usrEmailId"
                value={vm.model.usrEmailId}
                inputType={InputType.Email}
                onChange={(e: any) => {
                  vm.onInputChange(e, 'usrEmailId', vm.model);
                }}
                label="Email Address"
                required={true}
                maxLength={100}
              />
            </div>
            <div>
              <InputDropdown
                id="usrRoleId"
                value={vm.model?.usrRoleId}
                options={vm.roleList}
                optionLabel="value"
                optionValue="key"
                label="User Type"
                placeholder='Select'
                className="w-full"
                required={true}
                onChange={(e) => vm.onRoleChange(e)}
              />
            </div>
          </div>
          <div className='grid grid-cols-4 gap-4'>
            <div>
              <Input
                id="phoneNbr"
                label='Phone'
                value={vm.model.phoneNbr}
                inputType={InputType.Phone}
                onChange={(e) => vm.onInputChange(e, 'phoneNbr', vm.model)}
                required={false}
              />
            </div>
            {vm.isMemberRole && <>
              <div>
                <InputDropdown
                  id="personGender"
                  value={vm.model?.personGender}
                  options={vm.genderList}
                  optionLabel="value"
                  optionValue="key"
                  label="Gender"
                  placeholder='Select'
                  className="w-full"
                  required={true}
                  onChange={(e) => vm.onInputChange(e, 'personGender', vm.model)}
                />
              </div>
              <div>
                <InputCalendar
                  id="personDob"
                  value={vm.model.personDob!}
                  label='DOB'
                  onChange={(e: any) => {
                    vm.onInputChange(e, 'personDob', vm.model);
                  }}
                  required={true}
                  maxDate={new Date()}
                />
              </div>
              <div>
                <Input
                  id="personSsn"
                  label='SSN (9 digits)'
                  value={vm.model?.personSsn}
                  inputType={InputType.Secret}
                  onChange={(e) => vm.onInputChange(e, 'personSsn', vm.model)}
                  placeholder=""
                  maxLength={9}
                  required={true}
                />
              </div>
            </>}
          </div>
          {vm.isMemberRole &&
            <div className='grid grid-cols-4 gap-4'>
              <div>
                <Input
                  id="employer"
                  value={vm.model.employer}
                  inputType={InputType.Text}
                  onChange={(e: any) => {
                    vm.onInputChange(e, 'employer', vm.model);
                  }}
                  label="Employer"
                  required={false}
                  maxLength={50}
                />
              </div>
              <div>
                <Input
                  id="membershipType"
                  value={vm.model.membershipType}
                  inputType={InputType.Text}
                  onChange={(e: any) => {
                    vm.onInputChange(e, 'membershipType', vm.model);
                  }}
                  label="Membership Type"
                  required={false}
                  maxLength={50}
                />
              </div>
              <div>
                <Input
                  id="personPin"
                  value={vm.model.personPin}
                  inputType={InputType.Text}
                  onChange={(e: any) => {
                    vm.onInputChange(e, 'personPin', vm.model);
                  }}
                  label="PIN"
                  required={false}
                  maxLength={9}
                />
              </div>
              <div >
                <Input
                  id="scersId"
                  value={vm.model.scersId}
                  inputType={InputType.Text}
                  onChange={(e: any) => {
                    vm.onInputChange(e, 'scersId', vm.model);
                  }}
                  label="SCERS ID"
                  required={false}
                  maxLength={9}
                />
              </div>
            </div>
          }
          {!vm.isMemberRole &&
            <div className='grid grid-cols-1 gap-4'>
              <label className='font-bold text-15px text-black mb-1 block'>Assign Permissions</label>
              <TreeView
                id="selectedPermissions"
                value={vm.model?.permission?.permissions}
                selectionMode="checkbox"
                selectionKeys={vm.model?.permission?.selectedPermissions}
                onSelectionChange={(e) => vm.onSelectedPrivilege(e)}
                filter={true}
                filterPlaceholder={'Search'}
                togglerTemplate={(node: any, options: TreeTogglerTemplateOptions) => (
                  <span className="pi pi-angle-right mr-2 hidden" onClick={options.onClick}></span>
                )}
              />
            </div>
          }
          <div className="flex items-center justify-center gap-x-6 my-6">
            <Button title="Submit"
              onClick={async () => await vm.saveAsync()} />
            <Button title="Cancel" severity="secondary" onClick={() => vm.navigate(-1)} />
          </div>
        </div>
      </View >
    </>
  );

});

export default UserView;
