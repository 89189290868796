import { action, makeObservable, observable } from 'mobx';
import { Service } from 'typedi';
import { BaseViewModel } from '../../../core/services/BaseViewModel';
import { Appl } from '../../../core/Appl';
import { Role } from '../../../core/services/Enums';
import { SubmissionModel } from '../../admin/sra/retiree/memberCreate/ApplyModels';
import { ISraService } from '../../admin/sra/SraService';

@Service()
export default class DashboardViewModel extends BaseViewModel {
  @observable public printDialogVisible = false;
  @observable public submission?: SubmissionModel;
  @observable public afrFile?: any;
  private _service: ISraService;
  constructor() {
    super();
    this._service = Appl.Services.get<ISraService>('ISraService');
    makeObservable(this);
  }

  @action public async initAsync(): Promise<void> {

    this.pageTitle = 'Member - Dashboard';
    if (Appl.User.hasRole(Role.Admin)) {
      this.pageTitle = 'Admin - Dashboard';
    }
    const result = await this._service.getSubmissionByMemberIdAsync(Appl.User.userId!, false);
    if (result.success) {
      if (result.data !== "") {
        this.submission = result.data;
      }

    } else {
      Appl.Error.handle(result.error);
    }
  }

  // @action public async showPrintDialog(): Promise<void> {
  //   const result = await this._service?.downloadAsync(`/retirement/Retiree/getAppContentAsPdf/${this.submission?.submissionId}/6105b`);
  //   if (result.success) {
  //     this.afrFile = result.data
  //   } else {
  //     Appl.Error.handle(result.error);
  //   }
  //   this.printDialogVisible = true;
  // }

  // @action public async hidePrintDialog(): Promise<void> {
  //   this.printDialogVisible = false;
  // }
}
