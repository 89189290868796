import { PermissionModel } from "../../role/permission/PermissionListModel";

export class UserModel {
  usrId?: number;
  usrEmailId?: string = '';
  usrRoleId?: number = undefined;
  fname?: string = '';
  lname?: string = '';
  phoneNbr?: string = '';
  personId?: number = undefined;
  usrGuid?: string = '';
  personSsn?: string = '';
  personDob?: string & Date | null = undefined;
  personGender?: string = '';
  employer?: string = '';
  membershipType?: string = '';
  personPin?: string = '';
  scersId?: string = '';
  personType?: number = undefined;
  selectedPermissions?: any;
  permission?: PermissionModel = undefined;
}
