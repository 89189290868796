import { ErrorBoundary } from "react-error-boundary";
import { ErrorView } from "../pages/ErrorView";
import { Suspense } from "react";
import { DynamicIcon } from 'lucide-react/dynamic';

type IconProps = {
    name: string;
    size?: number;
    className?: string;
    title?: string;
    color?: string;
};

export const Icon: React.FC<IconProps> = ({ size = 24, className = 'm-2', color = 'black', ...props }) => {
    const fallback = <div style={{ background: '#000000', width: 24, height: 24 }} />
    return (
        <>
            <ErrorBoundary FallbackComponent={ErrorView}
                onError={() => {
                    fallback
                    // reset the state of your app so the error doesn't happen again
                }}
                onReset={() => {
                    // reset the state of your app so the error doesn't happen again
                }}>
                <Suspense fallback={fallback}>
                    <DynamicIcon name={props.name as any} size={size} color={color} className={className} />
                </Suspense>
            </ErrorBoundary>
        </>
    );
};