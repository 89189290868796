import React from 'react';
import { observer } from 'mobx-react';
import { Button, DataGrid, Icon, PdfViewer, SearchToolbar, View, ViewHeader } from '../../../../../core/components/Index';
import { Link, useNavigate } from 'react-router-dom';
import useAsyncEffect from 'use-async-effect';
import { Column } from 'primereact/column';
import ChangeOfAddressListViewModel from './ChangeOfAddressListViewModel';
import { ChangeOfAddressListModel } from './ChangeOfAddressListModel';
import { Appl } from '../../../../../core/Appl';
import { formatDate } from '../../../../../core/services/Helper';
import { Dialog } from 'primereact/dialog';
import { Button as PrimeButton } from 'primereact/button';
import { FileInput } from 'lucide-react';
import { AppPermissions } from '../../../../../core/services/AppPermissions';

export const ChangeOfAddressListView: React.FC = observer(() => {
  const vm = Appl.Services.get<ChangeOfAddressListViewModel>('ChangeOfAddressListViewModel');
  vm.navigate = useNavigate();

  useAsyncEffect(async () => {
    await vm.getPagedAsync();
  }, []);


  const createdDateTemplate = (row: ChangeOfAddressListModel) => {
    return formatDate(row.createdDate);
  };
  const changeEffDateTemplate = (row: ChangeOfAddressListModel) => {
    return formatDate(row.changeEffDate);
  };


  const actionTemplate = (row: ChangeOfAddressListModel) => {
    let editLink = `/members/change-of-address/${row.mbrChangeOfAddressId}`;
    return (
      <div className="flex">
        <Link to={editLink} title="Edit Address">
          <Icon name="file-pen-line" size={24} className="link me-2" title="Edit" />
        </Link>
        <span className="link"
          onClick={async () => await vm.showPrintDialog(row.mbrChangeOfAddressId!)}
          title='View/Print'>
          <Icon name="printer" size={24} color="black" className="me-2" title="Print" />
        </span>
      </div>
    );
  };

  const printApplicationFooterTemplate = () => {
    return (
      <>
        <div className="modal-footer">
          <Button title="Close" onClick={() => vm.hidePrintDialog()} />
        </div>
      </>
    );
  };

  return (
    <>
      <View id="ChangeOfAddressListView" pageTitle={vm.pageTitle}
        authKey={AppPermissions.AddressNotifications_6_5_VIEW}
        showMenuBar={false}
      >
        <ViewHeader title={vm.pageTitle} description={vm.pageDescription}>
          <SearchToolbar
            filterPlaceholder="Full Name / SSN"
            filter={vm.searchRequest?.filter}
            onFilterChange={(e) => vm.onInputChange(e, 'filter', vm.searchRequest)}
            onRefresh={async () => await vm.getPagedAsync()}
            addNewVisible={false}
            refreshVisible={false}
            showButton={false}
            // run search on input change
            searchOnInputChange={true}
          />
        </ViewHeader>
        <div className="flex space-x-1">
          <PrimeButton outlined severity="secondary" onClick={async () => await vm.export('csv')}
            className="!border-transparent !bg-transparent">
            <FileInput className="w-4 h-4 mr-1.5" />
            Export
          </PrimeButton>
        </div>
        <DataGrid
          pageSize={vm.pageSize}
          dataSource={vm.items}
          totalRowsCount={vm.totalRowsCount}
          searchRequest={vm.searchRequest}
          onRefresh={() => vm.getPagedAsync()}>
          <Column field="mbrChangeOfAddressId" header="ID" sortable={true} hidden={false} />
          <Column field="createdDate" header="RECEIVED" sortable={true} body={createdDateTemplate} />
          <Column field="fullName" header="FULL NAME" sortable={true} />
          <Column field="ssn" header="SSN" sortable={true} />
          <Column field="changeEffDate" header="CHANGE EFF DATE" sortable={true} body={changeEffDateTemplate} />
          <Column body={actionTemplate} header="Manage" />
        </DataGrid>
        <Dialog
          header="Print Change Of Address"
          visible={vm.printDialogVisible}
          maximizable
          maximized={true}
          modal
          onHide={() => vm.hidePrintDialog()}
          footer={printApplicationFooterTemplate}
          closable={true}>
          <PdfViewer data={vm.afrFile} />
        </Dialog>
      </View>
    </>
  );
});
