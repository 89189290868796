import { observer } from 'mobx-react';
import { Button, Input, InputCalendar, InputDropdown, InputType, View, ViewHeader } from '../../../../../../core/components/Index';
import useAsyncEffect from 'use-async-effect';
import { useNavigate, useParams } from 'react-router-dom';
import { Appl } from '../../../../../../core/Appl';
import StateList from '../../../../../../assets/data/States.json';
import DeathReportViewModel from './DeathReportViewModel';
import { AppPermissions } from '../../../../../../core/services/AppPermissions';

const DeathReportView = observer(() => {
  const vm = Appl.Services.get<DeathReportViewModel>('DeathReportViewModel');
  vm.navigate = useNavigate();
  let { id } = useParams();
  useAsyncEffect(async () => {
    await vm.initAsync(id as unknown as number);
  }, []);

  return (

    <View
      id="DeathReportView"
      pageTitle={vm.pageTitle}
      authKey={AppPermissions.DeathNotifications_8_7_VIEW}
      showMenuBar={false}
    >
      <ViewHeader title={vm.pageTitle} description={vm.pageDescription} helpPageId="" backUrl="" />
      <div className="flex space-x-3 mb-3">
        {vm.isReadOnlyForm && <>
          <Button title="Back to Queue" className='!rounded-lg' onClick={() => vm.navigate(-1)} />
          <Button title="Edit" className='!rounded-lg' severity='warning' outlined onClick={() => vm.setFormMode('Edit')} />
        </>
        }
        {!vm.isReadOnlyForm && <>
          <Button title="Save" className='!rounded-lg'
            disabled={!vm.formDataChanged}
            onClick={async () => await vm.saveAsync()} />
          <Button title="Cancel" className='!rounded-lg' severity="secondary"
            onClick={() => vm.isFormDataChanged(vm.model)
              ? Appl.ConfirmBox.show('If you cancel now, any unsaved changes will be lost.<br /><br />Do you want to proceed?', 'Are You Sure?', '', 'Confirm', 'Go Back', () => vm.onCancel())
              : vm.setFormMode("View")
            }
          />
        </>
        }
      </div>
      <hr className='mb-4' />
      <div className="flex flex-wrap lg:flex-nowrap gap-4" id="formContainer">
        <div style={{ width: '50%' }}>
          <div className="bg-white rounded-xl mb-4 py-6 px-6 shadow-card border border-gray-25 space-y-8" >
            <h2 className="font-medium text-lg md:text-2xl text-gray-800">Record Information</h2>
            <div className="grid md:grid-cols-2 gap-4 mb-3">
              <div>
                <InputCalendar
                  id="createdDate"
                  value={vm.model?.createdDate!}
                  label="Date Received"
                  onChange={(e) => {
                    vm.onInputChange(e, 'createdDate', vm.model);
                  }}
                  required={false}
                  readOnly={vm.isReadOnlyForm}
                  maxDate={new Date()}
                />
              </div>
              <div>
                <InputDropdown
                  id="lovSubmissionStatusId"
                  value={vm.model?.lovSubmissionStatusId}
                  options={vm.submissionStatusList}
                  optionLabel="value"
                  optionValue="key"
                  label="Status"
                  placeholder='Select'
                  style={{ width: '245px' }}
                  className="w-full"
                  required={false}
                  onChange={(e) => vm.onInputChange(e, 'lovSubmissionStatusId', vm.model)}
                  readOnly={vm.isReadOnlyForm}
                />
              </div>
            </div>
          </div>
          <div className="bg-white rounded-xl mb-4 py-6 px-6 shadow-card border border-gray-25 space-y-8" >
            <h2 className="font-medium text-lg md:text-2xl text-gray-800">Deceased Information</h2>
            <div className="grid md:grid-cols-2 gap-4 mb-3">
              <div>
                <Input
                  id="mbrFirstName"
                  value={vm.model.mbrFirstName}
                  inputType={InputType.Text}
                  onChange={(e) => {
                    vm.onInputChange(e, 'mbrFirstName', vm.model);
                  }}
                  label="First Name"
                  required={false}
                  maxLength={100}
                  readOnly={vm.isReadOnlyForm}
                />
              </div>
              <div>
                <Input
                  id="mbrLastName"
                  value={vm.model.mbrLastName}
                  inputType={InputType.Text}
                  onChange={(e) => {
                    vm.onInputChange(e, 'mbrLastName', vm.model);
                  }}
                  label="Last Name"
                  required={false}
                  maxLength={100}
                  readOnly={vm.isReadOnlyForm}
                />
              </div>
            </div>
            <div className="grid md:grid-cols-2 gap-4 mb-3">
              <div>
                <Input
                  id="mbrSsn"
                  value={vm.model?.mbrSsn}
                  label='SSN'
                  inputType={InputType.Text}
                  onChange={(e) => vm.onInputChange(e, 'mbrSsn', vm.model)}
                  maxLength={4}
                  required={false}
                  readOnly={vm.isReadOnlyForm}
                />
              </div>
            </div>
            <div className="grid md:grid-cols-2 gap-4 mb-3">
              <div>
                <InputCalendar
                  id="mbrBirthDate"
                  value={vm.model?.mbrBirthDate!}
                  label="Date of Birth"
                  onChange={(e) => {
                    vm.onInputChange(e, 'mbrBirthDate', vm.model);
                  }}
                  required={false}
                  readOnly={vm.isReadOnlyForm}
                  maxDate={new Date()}
                />
              </div>

            </div>
            <div className="grid md:grid-cols-2 gap-4 mb-3">
              <div>
                <InputCalendar
                  id="mbrDeathDate"
                  value={vm.model.mbrDeathDate!}
                  label="Date of Death"
                  onChange={(e) => {
                    vm.onInputChange(e, 'mbrDeathDate', vm.model);
                  }}
                  required={false}
                  readOnly={vm.isReadOnlyForm}
                  maxDate={new Date()}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white rounded-xl mb-4 py-6 px-6 shadow-card border border-gray-25 space-y-8" style={{ width: '50%' }}>
          <h2 className="font-medium text-lg md:text-2xl text-gray-800">Contact Information</h2>
          <div className="grid md:grid-cols-2 gap-4 mb-3">
            <div>
              <Input
                id="reqFirstName"
                value={vm.model.reqFirstName}
                inputType={InputType.Text}
                onChange={(e) => {
                  vm.onInputChange(e, 'reqFirstName', vm.model);
                }}
                label="First Name"
                required={false}
                maxLength={100}
                readOnly={vm.isReadOnlyForm}
              />
            </div>
            <div>
              <Input
                id="reqLastName"
                value={vm.model.reqLastName}
                inputType={InputType.Text}
                onChange={(e) => {
                  vm.onInputChange(e, 'reqLastName', vm.model);
                }}
                label="Last Name"
                required={false}
                maxLength={100}
                readOnly={vm.isReadOnlyForm}
              />
            </div>
          </div>
          <div className="grid md:grid-cols-1 gap-4 mb-3">
            <div>
              <InputDropdown
                id="mbrRelationId"
                value={vm.model?.mbrRelationId}
                options={vm.memberRelationList}
                optionLabel="value"
                optionValue="key"
                label="Relationship to the Deceased"
                placeholder='Select'
                style={{ width: '100%' }}
                className="w-full"
                required={false}
                onChange={(e) => vm.onInputChange(e, 'mbrRelationId', vm.model)}
                readOnly={vm.isReadOnlyForm}
              />
            </div>
          </div>
          <div className="grid md:grid-cols-1 gap-4 mb-3">
            <div>
              <Input
                id="reqAddressLine1"
                value={vm.model.reqAddressLine1}
                inputType={InputType.Text}
                onChange={(e) => {
                  vm.onInputChange(e, 'reqAddressLine1', vm.model);
                }}
                label="Address"
                required={false}
                maxLength={100}
                readOnly={vm.isReadOnlyForm}
              />
            </div>
          </div>
          <div className="flex gap-4 mb-3">
            <div className='flex-shrink-0 w-full flex-1'>
              <Input
                id="reqCity"
                value={vm.model.reqCity}
                inputType={InputType.Text}
                onChange={(e) => {
                  vm.onInputChange(e, 'reqCity', vm.model);
                }}
                label="City"
                required={false}
                maxLength={100}
                readOnly={vm.isReadOnlyForm}
              />
            </div>

            <div>
              <InputDropdown
                id="reqState"
                value={vm.model?.reqState}
                options={StateList}
                optionValue="value"
                optionLabel="value"
                label="State"
                placeholder='Select'
                className="w-full"
                required={false}
                onChange={(e) => vm.onInputChange(e, 'reqState', vm.model)}
                readOnly={vm.isReadOnlyForm}
              />
            </div>
            <div className='flex-shrink-0 w-24'>
              <Input
                id="reqZipCode"
                value={vm.model?.reqZipCode}
                inputType={InputType.Text}
                onChange={(e) => vm.onInputChange(e, 'reqZipCode', vm.model)}
                label="Zip"
                maxLength={5}
                required={false}
                readOnly={vm.isReadOnlyForm}
              />
            </div>

          </div>
          <div className="grid md:grid-cols-2 gap-4 mb-3">
            <div>
              <Input
                id="reqEmail"
                value={vm.model.reqEmail}
                inputType={InputType.Email}
                onChange={(e) => {
                  vm.onInputChange(e, 'reqEmail', vm.model);
                }}
                label="Email Address"
                required={false}
                maxLength={100}
                readOnly={vm.isReadOnlyForm}
              />
            </div>
            <div>
              <div className="flex-1 min-w-[240px]">
                <Input
                  id="reqPhone"
                  label={`Phone ${vm.model.reqPhoneType ? " - " + vm.model.reqPhoneType : ""}`}
                  value={vm.model.reqPhone}
                  inputType={InputType.Phone}
                  onChange={(e) => vm.onInputChange(e, 'reqPhone', vm.model)}
                  required={false}
                  readOnly={vm.isReadOnlyForm}
                />
              </div>
            </div>
          </div>
          <div className="flex gap-4 mb-3">
            <div>
              <InputDropdown
                id="mbrContactPrefId"
                value={vm.model?.mbrContactPrefId}
                options={vm.contactPrefList}
                optionValue="key"
                optionLabel="value"
                label="Preferred Method"
                placeholder='Select'
                style={{ width: '375px' }}
                className="w-full"
                required={false}
                onChange={(e) => vm.onInputChange(e, 'mbrContactPrefId', vm.model)}
                readOnly={vm.isReadOnlyForm}
              />
            </div>
          </div>
        </div>
      </div>
    </View >
  );
});

export default DeathReportView;