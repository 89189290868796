import { Service } from 'typedi';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { CheckboxChangeEvent } from 'primereact/checkbox';
import { BaseAddEditViewModel } from '../../../../../core/services/BaseViewModel';
import { Appl } from '../../../../../core/Appl';
import { toLocalDate, toServerDate } from '../../../../../core/services/Helper';
import { IReleaseNoteService } from '../ReleaseNoteService';
import { ReleaseNotesModel } from './ReleaseNotesModel';

@Service() // eslint-disable-next-line
export default class ReleaseNotesViewModel extends BaseAddEditViewModel {
    private service: IReleaseNoteService;

    @observable public model: Array<ReleaseNotesModel> = [];
    constructor() {
        super('Release Notes');
        this.service = Appl.Services.get<IReleaseNoteService>('IReleaseNoteService');
        makeObservable(this);
    }

    @action
    public async initAsync(): Promise<void> {
        const result = await this.service?.getPagedAsync(`/utility/releasenotes/search`, {
            skip: 0, take: 1000, sortColumn: 'buildId', sortAscending: false, filter: ''
        });
        if (result.success) {
            runInAction(() => {
                this.model = result.data.data;
            })
        } else {
            Appl.Error.handle(result.error);
        }
    }
}
