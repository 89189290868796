import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ChevronDown, ChevronRight, ArrowLeftToLine, Menu, X } from 'lucide-react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { getMenuItems, NavItem } from '../../core/services/Models';
import { Appl } from '../../core/Appl';
import { Icon } from '../../core/components/Icon';

interface SidebarViewNavigationProps {
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
}

const SidebarViewNavigation: React.FC<SidebarViewNavigationProps> = ({ collapsed, setCollapsed }) => {
  const location = useLocation();
  const [expandedItems, setExpandedItems] = React.useState<Set<string>>(new Set());
  const [mobileMenuVisible, setMobileMenuVisible] = React.useState(false);

  const isItemActive = (item: NavItem): boolean => {
    if (item.path === location.pathname) {
      return true;
    }
    if (item.alias?.some(aliasPath => location.pathname.includes(aliasPath))) {
      return true;
    }
    if (item.children) {
      return item.children.some(child => isItemActive(child));
    }
    return false;
  };

  const getActiveParentIds = (items: NavItem[]): string[] => {
    const activeIds: string[] = [];

    const traverse = (item: NavItem) => {
      if (isItemActive(item)) {
        activeIds.push(item.id);
        return true;
      }
      if (item.children) {
        const hasActiveChild = item.children.some(traverse);
        if (hasActiveChild) {
          activeIds.push(item.id);
        }
        return hasActiveChild;
      }
      return false;
    };

    items.forEach(traverse);
    return activeIds;
  };

  let navigationItems: { section: string | undefined; items: NavItem[] }[] = [];
  navigationItems = getMenuItems(Appl.User.permissions!);

  React.useEffect(() => {
    const activeParentIds = navigationItems.flatMap(section =>
      getActiveParentIds(section.items)
    );
    setExpandedItems(new Set(activeParentIds));
  }, [location.pathname]);

  const toggleExpanded = (id: string) => {
    setExpandedItems(prev => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });
  };

  const renderNavItem = (item: NavItem, depth: number = 0, isMobile: boolean = false) => {
    const isExpanded = expandedItems.has(item.id);
    const isActive = item.path === location.pathname || item.alias?.some(aliasPath => location.pathname.includes(aliasPath));
    const hasChildren = item.children && item.children.length > 0;
    const paddingLeft = isMobile ? depth * 16 + 16 : (collapsed ? 16 : depth * 24 + 24);
    // const Icon = item.icon ? item.icon : 'div';

    return (
      <div key={item.id}>
        {item.path ? (
          <Link
            to={item.path}
            className={`flex items-center px-6 py-2 text-sm hover:bg-gray-50 hover:text-orange-500 ${isActive ? 'text-orange-500' : 'text-gray-700'
              }`}
            style={{ paddingLeft: `${paddingLeft}px` }}
            onClick={() => isMobile && setMobileMenuVisible(false)}
          >
            {item.icon && <Icon name={item.icon} className="h-5 w-5 flex-shrink-0" />}
            <span className={`ml-3 ${collapsed && !isMobile ? 'w-0 opacity-0' : 'w-auto opacity-100'} whitespace-nowrap overflow-hidden`}>
              {item.label}
            </span>
          </Link>
        ) : (
          <button
            onClick={() => toggleExpanded(item.id)}
            className="flex w-full items-center justify-between px-6 py-2 text-sm text-gray-700 hover:bg-gray-50"
            style={{ paddingLeft: `${paddingLeft}px` }}
          >
            <div className="flex items-center min-w-0">
              {item.icon && <Icon name={item.icon} className="h-5 w-5 flex-shrink-0" />}
              <span className={`ml-3 ${collapsed && !isMobile ? 'w-0 opacity-0' : 'w-auto opacity-100'} whitespace-nowrap overflow-hidden`}>
                {item.label}
              </span>
            </div>
            {(!collapsed || isMobile) && hasChildren && (
              isExpanded ? (
                <ChevronDown className="h-4 w-4 flex-shrink-0" />
              ) : (
                <ChevronRight className="h-4 w-4 flex-shrink-0" />
              )
            )}
          </button>
        )}
        {hasChildren && isExpanded && (!collapsed || isMobile) && (
          <div className="space-y-1">
            {item.children?.map(child => renderNavItem(child, depth + 1, isMobile))}
          </div>
        )}
      </div>
    );
  };

  const renderNavigationContent = (isMobile: boolean = false) => (
    <div className="flex-1 overflow-y-auto pt-6">
      {navigationItems.map(({ section, items }, index) => (
        <div key={section} className={`pt-1 pb-4 relative`}>
          {(!collapsed || isMobile) ? (
            <div className="px-4 mb-2 text-xs font-semibold text-gray-500 uppercase tracking-wider">
              {section}
            </div>
          ) : (
            <div />
          )}
          {index > 0 && collapsed && !isMobile && (
            <div className="py-1 top-0 left-4 right-4 border-t border-gray-200" />
          )}
          <div className="mt-2 space-y-1">
            {items.map(item => renderNavItem(item, 0, isMobile))}
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <>
      {/* Mobile Menu Button */}
      <div className="md:hidden fixed -top-1 left-0 z-20 m-4">
        <Button
          severity="secondary"
          outlined
          onClick={() => setMobileMenuVisible(true)}
          className="!border-none !bg-transparent"
        >
          <Menu size={20} />
        </Button>
      </div>

      {/* Mobile Sidebar */}
      <Sidebar
        visible={mobileMenuVisible}
        onHide={() => setMobileMenuVisible(false)}
        className="p-0 w-64"
        showCloseIcon={false}
        pt={{
          content: {
            className: 'p-0'
          },
          root: {
            className: 'w-72'
          },
          header: {
            className: 'p-0 bg-red-500 border-b'
          }
        }}
      >
        <div className="flex flex-col h-full bg-white">
          <div className="flex items-center justify-between p-4 border-b">
            <h2 className="text-lg font-semibold"></h2>
            <Button
              severity="secondary"
              outlined
              onClick={() => setMobileMenuVisible(false)}
              className="!border-none !background-none"
            >
              <X size={20} />
            </Button>
          </div>
          {renderNavigationContent(true)}
        </div>
      </Sidebar>

      {/* Desktop Sidebar */}
      <div
        className={`bg-red-500 duration-200 bg-white border-r border-gray-200 fixed inset-y-0 z-10 hidden transition-[width] ease-linear md:flex flex-col left-0 
          h-[calc(100vh-100px)] top-[100px] ${collapsed ? 'w-16' : 'w-64'}`}
      >
        {renderNavigationContent()}
        <div className="mt-auto border-t border-gray-200">
          <button
            onClick={() => setCollapsed(!collapsed)}
            className="flex w-full items-center px-4 py-3 text-sm text-gray-700 hover:bg-gray-50 hover:text-orange-500"
          >
            <ArrowLeftToLine className={`h-5 w-5 flex-shrink-0 transition-all duration-100 ${collapsed && 'rotate-180'}`} />
            <span className={`ml-3 duration-200 ${collapsed ? 'w-0 opacity-0' : 'w-auto opacity-100'} whitespace-nowrap overflow-hidden`}>
              Collapsed View
            </span>
          </button>
        </div>
      </div>
    </>
  );
};

export default SidebarViewNavigation;