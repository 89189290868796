import React from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import ApplyViewModel from './ApplyViewModel';
import useAsyncEffect from 'use-async-effect';
import { Appl } from '../../../../../core/Appl';
import { formatDate } from '../../../../../core/services/Helper';
import { Button, Icon, PdfViewer, View } from '../../../../../core/components/Index';
import scerea from '../../../../../assets/images/scerea.png'
import { Dialog } from 'primereact/dialog';
import { SubmissionStatus } from '../../SraEnums';
import { AppPermissions } from '../../../../../core/services/AppPermissions';

export const SubmissionStatusView: React.FC = observer(() => {
  const vm = Appl.Services.get<ApplyViewModel>('ApplyViewModel');
  let { memberId } = useParams();
  useAsyncEffect(async () => {
    await vm.getSubmissionStatus(memberId!);
  }, []);

  const statusMessage = () => {
    if (vm.submission?.lovSubmissionStatusId === SubmissionStatus.Submitted) {
      return "Your Retirement Application Has Been Submitted";
    } else if (vm.submission?.lovSubmissionStatusId === SubmissionStatus.InProgress) {
      return "Your Retirement Application is Under SCERS Review";
    } else if (vm.submission?.lovSubmissionStatusId === SubmissionStatus.Completed) {
      return "Your Retirement Application is Completed";
    }

    return "Your Retirement Application is Draft";
  }

  return (
    <View id="SubmissionStatusView" authKey={AppPermissions.AuthenticatedUser}
      showMenuBar={Appl.User.isAuthenticated}
    >
      <div className="space-y-[36px]">
        <h1 className="text-32px tracking--1percent text-neutral-800 font-semibold mb-4">{statusMessage()}</h1>
        <div className="space-y-[15px]"><h2 className="font-semibold text-gray-900 text-22px">Congratulations!</h2>
          <p className="text-black tracking--1_5percent text-black font-normal text-15px text-justify">We commend you for taking this important step towards your retirement.</p></div>
        <div className='flex flex-wrap md:flex-nowrap gap-8'>
          <div className="w-full flex flex-col items-center   border border-[#DEDEDE] rounded-lg p-6 space-y-[32px]">
            <p className="text-primary-500 text-22px font-semibold text-center">Service Retirement Application</p>
            <PDFIcon className='w-12 h-12' />
            <div className='max-w-[312px] w-full space-y-1 text-15px text-black'>
              <div className='flex gap-3 text-start justify-between'>
                <p className="font-bold ">Date of Retirement:</p>
                <p>{vm.submission?.retirementDate ? formatDate(vm.submission?.retirementDate) : '-'}</p>
              </div>
              <div className='flex gap-3 text-start justify-between'>
                <p className="font-bold ">Date of Submission:</p>
                <p>{formatDate(vm.submission?.submissionDate)}</p>
              </div>
              <div className='flex gap-3 text-start justify-between'>
                <p className="font-bold ">Submission ID:</p>
                <p>ORA-{vm.submission?.submissionId}</p>
              </div>

            </div>
            <hr className='bg-red-500  w-full' />
            <Button title="Download Your Application" onClick={async () => await vm.showPrintDialog(vm.submission?.submissionId!)} />
          </div>
          <div className="w-full flex flex-col items-center   border border-[#DEDEDE] rounded-lg p-6 space-y-[32px]">
            <p className="text-primary-500 text-22px font-semibold text-center">What to Expect Next</p>
            <div className='flex flex-col gap-4 text-15px text-black'>
              <p><span className="font-bold">Check Your Email</span><br />We've sent you a confirmation email with a receipt of your submission. Keep an eye on your inbox!</p>
              <p><span className="font-bold">Need to Make Changes?</span><br />If you need to cancel or withdraw your application, just give us a call at (916) 874-9119. We're here to help.</p>
              <p><span className="font-bold">Don't Forget Your Documents</span><br />If you still need to upload any required documents, please do so soon. We can't process your application until all the necessary paperwork is in.</p>
            </div>
          </div>
        </div>
        <div className="bg-cream-50 w-full flex flex-col items-center justify-center border  border-[#DEDEDE] rounded-sm p-4">
          <div className='flex gap-4 tracking--1_5percent text-15px leading-26px'>
            <img src={scerea} width={100} height={100} className='flex-shrink-0' />
            <div>
              <p className="font-bold">Did you know?</p><p>The Sacramento County Retired Employees Association (SCREA) helps retirees stay connected and informed. As the only recognized organization for Sacramento County retirees, SCREA supports members' well-being and smooth transitions into retirement. Join SCREA for benefits like community, networking, and access to valuable resources. Click below to learn more!</p>
            </div>
          </div>
          <div className="text-center pt-4">
            <Button title="Learn More" severity='warning' />
          </div>
        </div>
      </div>
      <Dialog
        header="Service Retirement Application"
        visible={vm.printDialogVisible}
        maximizable
        maximized={true}
        modal
        onHide={() => vm.hidePrintDialog()}
        closable={true}>
        <PdfViewer data={vm.afrFile} />
      </Dialog>
    </View >
  );
});


export const PDFIcon = (props: any) => (
  <svg width="52" height="50" viewBox="0 0 52 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M39.8722 48H24.3373C18.8144 48 14.3373 43.5228 14.3373 38V12C14.3373 6.47715 18.8144 2 24.3373 2H34.474L49.8722 17.3982V38C49.8722 43.5228 45.395 48 39.8722 48Z"
      fill="#EEF1F7"
      stroke="#CBD0DC"
      stroke-width="4"
    />
    <rect x="0.12793" y="22.0938" width="34.8837" height="20.3488" rx="7" fill="#D82042" />
    <path
      d="M5.90955 37.2095V27.4844H9.37603C10.1326 27.4844 10.7595 27.6221 11.2565 27.8975C11.7535 28.1729 12.1255 28.5496 12.3724 29.0277C12.6193 29.5025 12.7428 30.0375 12.7428 30.6327C12.7428 31.231 12.6178 31.7692 12.3677 32.2472C12.1207 32.7221 11.7472 33.0988 11.247 33.3774C10.75 33.6528 10.1247 33.7905 9.37128 33.7905H6.98748V32.5464H9.23832C9.71635 32.5464 10.1042 32.4641 10.4017 32.2995C10.6993 32.1317 10.9177 31.9038 11.057 31.6157C11.1963 31.3276 11.266 30.9999 11.266 30.6327C11.266 30.2655 11.1963 29.9394 11.057 29.6545C10.9177 29.3696 10.6977 29.1464 10.397 28.9849C10.0994 28.8235 9.70685 28.7428 9.21933 28.7428H7.37687V37.2095H5.90955Z"
      fill="white"
    />
    <path
      d="M17.6113 37.2095H14.463V27.4844H17.711C18.6639 27.4844 19.4823 27.6791 20.1661 28.0685C20.8499 28.4547 21.3738 29.0103 21.7379 29.7352C22.1051 30.457 22.2887 31.3228 22.2887 32.3327C22.2887 33.3457 22.1035 34.2163 21.7331 34.9444C21.3659 35.6726 20.834 36.2329 20.1376 36.6255C19.4411 37.0148 18.599 37.2095 17.6113 37.2095ZM15.9303 35.9274H17.5306C18.2714 35.9274 18.8871 35.7881 19.3778 35.5095C19.8685 35.2278 20.2357 34.821 20.4795 34.2891C20.7232 33.7541 20.8451 33.102 20.8451 32.3327C20.8451 31.5698 20.7232 30.9224 20.4795 30.3905C20.2389 29.8587 19.8796 29.455 19.4015 29.1796C18.9235 28.9042 18.3299 28.7665 17.6208 28.7665H15.9303V35.9274Z"
      fill="white"
    />
    <path d="M24.1134 37.2095V27.4844H30.1441V28.7475H25.5807V31.7106H29.712V32.969H25.5807V37.2095H24.1134Z" fill="white" />
  </svg>
);