import { makeObservable, observable } from 'mobx';
import { Service } from 'typedi';
import { UserListModel } from './UserListModel';
import { BaseListViewModel } from '../../../../../core/services/BaseViewModel';
import { IAccountService } from '../../AccountService';
import { Appl } from '../../../../../core/Appl';

@Service()
export default class UserListViewModel extends BaseListViewModel<UserListModel> {
  @observable public model: UserListModel = new UserListModel();
  private service: IAccountService;

  constructor() {
    super('User List', 'usrId', false);
    this.service = Appl.Services.get<IAccountService>('IAccountService');
    makeObservable(this);
  }

  public async getPagedAsync(): Promise<void> {
    const response = await this.service.getPagedAsync(`/identity/user/search`, this.searchRequest);
    await this.handleGetAllServerResponse(response);
  }

}
