
export enum AppPermissions {
    PublicAccess = 'PublicAccess',
    AuthenticatedUser = 'AuthenticatedUser',
    Dashboards_1_1 = "1-1",
    Dashboard_2_1 = "2-1",
    Dashboard_2_1_VIEW = "2-1-VIEW",
    Retirements_3_3 = "3-3",
    ORAIntake_4_3 = "4-3",
    ORAIntake_4_3_VIEW = "4-3-VIEW",
    ChangeofAddress_5_5 = "5-5",
    AddressNotifications_6_5 = "6-5",
    AddressNotifications_6_5_VIEW = "6-5-VIEW",
    Deaths_7_7 = "7-7",
    DeathNotifications_8_7 = "8-7",
    DeathNotifications_8_7_VIEW = "8-7-VIEW",
    SecureFileUpload_9_9 = "9-9",
    Queue_10_9 = "10-9",
    Queue_10_9_VIEW = "10-9-VIEW",
    RequestForm_11_9 = "11-9",
    RequestForm_11_9_VIEW = "11-9-VIEW",
    Maintenance_12_12 = "12-12",
    LOVMaintenance_13_12 = "13-12",
    LOVMaintenance_13_12_VIEW = "13-12-VIEW",
    AuditLogs_14_14 = "14-14",
    EventNotifications_15_14 = "15-14",
    EventNotifications_15_14_VIEW = "15-14-VIEW",
    Account_16_16 = "16-16",
    Users_17_16 = "17-16",
    Users_17_16_VIEW = "17-16-VIEW",
    Release_18_18 = "18-18",
    ReleaseNotes_19_18 = "19-18",
    ReleaseNotes_19_18_VIEW = "19-18-VIEW",
    RAP_20_20 = "20-20",
    Calculator_21_20 = "21-20",
    Calculator_21_20_VIEW = "21-20-VIEW",
}
