import { Service } from 'typedi';
import { BaseService, IBaseService, ServerResponse } from '../../../core/services/BaseService';
import { Appl } from '../../../core/Appl';
import { UserModel } from './user/addEdit/UserModel';
import { RoleModel } from './role/addEdit/RoleModel';
import { PermissionModel } from './role/permission/PermissionListModel';

export interface IAccountService extends IBaseService {
  getRolesAsync(): Promise<ServerResponse>;
  createOrUpdateUserAsync(model: UserModel): Promise<ServerResponse>;
  createOrUpdateRoleAsync(model: RoleModel): Promise<ServerResponse>;
  getAllPermissionsByUserRoleIdAsync(userRoled: number, mode: 'user' | 'role'): Promise<ServerResponse>;
  assignPermissionsAsync(model: PermissionModel): Promise<ServerResponse>;
}

@Service() // eslint-disable-next-line
export class AccountService extends BaseService implements IAccountService {
  constructor() {
    super(`${Appl.Setting.Services.IdentityServiceUrl}`);
  }

  public async getRolesAsync(): Promise<ServerResponse> {
    try {
      const response = await this.client.get(`/identity/role/role-list`);
      return this.getResponse(response);
    } catch (error: unknown) {
      return await this.getErrorResponse(error);
    }
  }

  public async createOrUpdateUserAsync(model: UserModel): Promise<ServerResponse> {
    try {
      const response = await this.client.post(`/identity/user/create-or-update`, model);
      return this.getResponse(response);
    } catch (error: unknown) {
      return await this.getErrorResponse(error);
    }
  }

  public async createOrUpdateRoleAsync(model: RoleModel): Promise<ServerResponse> {
    try {
      const response = await this.client.post(`/identity/role/create-or-update`, model);
      return this.getResponse(response);
    } catch (error: unknown) {
      return await this.getErrorResponse(error);
    }
  }

  public async getAllPermissionsByUserRoleIdAsync(userRoled: number, mode: 'user' | 'role'): Promise<ServerResponse> {
    try {
      const response = await this.client.get(`/identity/permission/${mode}/${userRoled}`);
      return this.getResponse(response);
    } catch (error: unknown) {
      return await this.getErrorResponse(error);
    }
  }

  public async assignPermissionsAsync(model: PermissionModel): Promise<ServerResponse> {
    try {
      const response = await this.client.put(`/identity/permission/assign`, model);
      return this.getResponse(response);
    } catch (error: unknown) {
      return await this.getErrorResponse(error);
    }
  }
}
