import { Service } from 'typedi';
import { BaseService, IBaseService, SearchRequest, ServerResponse } from '../../../../core/services/BaseService';
import { Appl } from '../../../../core/Appl';
import { ManageReleaseNoteModel } from './addEdit/ManageReleaseNoteModel';

export interface IReleaseNoteService extends IBaseService {
    createOrUpdateAsync(model: ManageReleaseNoteModel): Promise<ServerResponse>;
}

@Service() // eslint-disable-next-line
export class ReleaseNoteService extends BaseService implements IReleaseNoteService {
    constructor() {
        super(`${Appl.Setting.Services.UtilityServiceUrl}`);
    }

    public async createOrUpdateAsync(model: ManageReleaseNoteModel): Promise<ServerResponse> {
        try {
            const response = await this.client.post(`/utility/releasenotes/create-or-update`, model);
            return this.getResponse(response);
        } catch (error: unknown) {
            return await this.getErrorResponse(error);
        }
    }
}
