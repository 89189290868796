import { action, makeObservable, observable } from 'mobx';
import { Service } from 'typedi';
import { BaseAddEditViewModel } from '../../../../../core/services/BaseViewModel';
import { IAccountService } from '../../AccountService';
import { Appl } from '../../../../../core/Appl';
import { PermissionModel } from './PermissionListModel';

@Service()
export default class RoleListViewModel extends BaseAddEditViewModel {

  private service: IAccountService;
  @observable public model!: PermissionModel;
  @observable public expandedKeys!: any;
  @observable public showCheckbox = true;

  constructor() {
    super('Assign Permissions for Role');
    this.service = Appl.Services.get<IAccountService>('IAccountService');
    makeObservable(this);
  }

  @action
  public async initAsync(mode: 'user' | 'role', id?: number): Promise<void> {
    this.model = {
      mode: mode,
      userRoleId: id!,
      selectedPermissions: [],
    };
    this.pageTitle = `Assign Permissions for ${mode} #${id}`;
    let result = await this.service.getAllPermissionsByUserRoleIdAsync(id!, mode);
    if (result.success) {
      this.model = result.data;
      this.model.userRoleId = id;
      this.model.mode = mode;
    } else {
      Appl.Error.handle(result.error);
    }
  }

  @action
  public async saveAsync(): Promise<void> {
    if (this.model.selectedPermissions == undefined || Object.keys(this.model.selectedPermissions).length == 0) {
      Appl.MessageBox.show('Please select atleast one Permission.', 'Select Permission');
      return;
    }
    this.model.selectedPermissions = await this.getSelectedPermissions()
    let result = await this.service.assignPermissionsAsync(this.model);
    if (result.success) {
      this.navigate(`/account/${this.model.mode}`)
    } else {
      Appl.Error.handle(result.error);
    }
  }

  @action public async getSelectedPermissions(): Promise<string[]> {
    const selectedItems = [];
    for (const [key] of Object.entries(this.model.selectedPermissions)) {
      selectedItems.push(key);
    }
    return selectedItems;
  }

  @action public onSelectedPrivilege(value: any): void {
    this.model.selectedPermissions = value;
  }

}
